import React, { useEffect, useRef, useState } from 'react';
import api, { Communication } from '../api';
import FullPageSpinner from './FullPageSpinner';
import EditCommunicationPassAudioRef from './EditCommunicatioPassAudioRef';
import { useParams } from 'react-router-dom';


const EditCommunication: React.FC = () => {

  const [loading, setLoading] = useState(true);
  const [communication, setCommunication] = useState<Communication | null>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const { communicationId } = useParams<{ communicationId: string }>();
  useEffect(() => {
    const fetchCommunication = async () => {
      const communication = await api.getCommunication(communicationId || "", false);
      setCommunication(communication);
      setLoading(false);
    };
    fetchCommunication();
  }, []);

  if (loading || !communication) {
    return <FullPageSpinner />
  }

  return (
    <>
      <audio ref={audioRef} />
      <EditCommunicationPassAudioRef audioRef={audioRef} communication={communication} startsPlaying={false} />
    </>
  );
};

export default EditCommunication;