import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { UseCase } from '../../models/UseCase';
import { FiMenu } from 'react-icons/fi';

type SortableItemProps = {
  id: string;
  item: UseCase;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
};

export const SortableItem: React.FC<SortableItemProps> = ({ id, item, onDelete, onEdit }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr ref={setNodeRef} style={style} {...attributes}>
      <td className="px-6 py-4 whitespace-nowrap cursor-move" {...listeners}>
        <div className="flex items-center">
          <span className="mr-2">
            <FiMenu className="text-gray-400" />
          </span>
          {item.title}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap cursor-move" {...listeners}>{item.category}</td>
      <td className="px-6 py-4 whitespace-nowrap cursor-move" {...listeners}>{item.oneOnOne ? 'Yes' : 'No'}</td>
      <td className="px-6 py-4 whitespace-nowrap cursor-move" {...listeners}>{item.adminOnly ? 'Yes' : 'No'}</td>
      <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
        <button
          onClick={() => onEdit(id)}
          className="text-indigo-600 hover:text-indigo-900 mr-2"
        >
          Edit
        </button>
        <button
          onClick={() => onDelete(id)}
          className="text-red-600 hover:text-red-900"
        >
          Delete
        </button>
      </td>
    </tr>
  );
};