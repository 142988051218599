import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import FullPageSpinner from './FullPageSpinner';

const AuthCallback: React.FC<{ setToken: (token: string) => void }> = ({ setToken }) => {
  const [authStatus, setAuthStatus] = useState<'processing' | 'success' | 'error'>('processing');
  const [error, setError] = useState<string | null>(null);
  const isRequestSentRef = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      if (isRequestSentRef.current) {
        return;
      }
      isRequestSentRef.current = true;
      const token = await api.getToken(window.location.search);
      setToken(token);
      setAuthStatus('success');
      redirectAfterLogin();
    };

    handleCallback();
  }, []);

  const redirectAfterLogin = () => {
    const redirectUrl = sessionStorage.getItem('redirectUrl') || '/';
    console.log('redirectUrl ', redirectUrl);
    sessionStorage.removeItem('redirectUrl'); // Clear the stored URL
    navigate(redirectUrl, { replace: true });
  };

  if (authStatus === 'processing') {
    return <FullPageSpinner />
  }

  if (authStatus === 'error') {
    return <div>{error}</div>;
  }

  return null;
};

export default AuthCallback;