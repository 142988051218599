import React from 'react';
import { useNavigate } from 'react-router-dom';
import AdminTable from './AdminTable';
import NavigationMenu from '../NavigationMenu';

const UserAdmin: React.FC = () => {
  const navigate = useNavigate();

  const sql = `
      with recently_active as (
        select 
          u.id, 
          u.email,
          u.first_name, 
          u.last_name, 
          u.created,
          sum(IF(c.status = 'FINISHED', 1, 0)) as interviews_finsihed, 
          count(c.id) as interviews,
          max(c.created) as last_interview,
          max(i.created) as last_partual_interview
        from 
          user u 
            left outer join communication c on c.user_id = u.id
            left outer join interview i on i.user_id = u.id and not exists (select 'X' from communication c2 where c2.interview_id = i.id)
        group by 1, 2, 3
      )
      select 
        ra.id,
        ra.email,
        ra.first_name, 
        ra.last_name, 
        ra.interviews_finsihed,
        ra.interviews,
        ra.created,
        ra.last_interview
      from recently_active ra
      order by  GREATEST(
          COALESCE(ra.created, '1000-01-01 00:00:00'),
          COALESCE(ra.last_partual_interview, '1000-01-01 00:00:00'),
          COALESCE(ra.last_interview, '1000-01-01 00:00:00')
      ) desc 
  `;

  const handleRowClick = (columnName: string, rowData: any) => {
    navigate(`/admin/communication?email=${encodeURIComponent(rowData.email)}`);
  };

  // Create click handlers for all visible columns
  const clickHandlers = {
    email: handleRowClick,
    firstName: handleRowClick,
    lastName: handleRowClick,
    interviewsFinsihed: handleRowClick,
    interviews: handleRowClick,
    created: handleRowClick,
    lastInterview: handleRowClick,
  };

  return (
    <>
      <NavigationMenu />
      <AdminTable
        sql={sql}
        title="Recently Active Users"
        clickHandlers={clickHandlers}
        hiddenColumns={['id']}
      />
    </>
  );
};

export default UserAdmin;