import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import FullPageSpinner from '../../components/FullPageSpinner';
import adminApi from '../../admin-api';

interface AdminTableProps {
  sql: string;
  title: string;
  clickHandlers?: { [key: string]: (columnName: string, rowData: any) => void };
  hiddenColumns?: string[];
  pageSize?: number;
}

const AdminTable: React.FC<AdminTableProps> = ({
  sql,
  title,
  clickHandlers = {},
  hiddenColumns = [],
  pageSize = 20
}) => {
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedCells, setExpandedCells] = useState<Set<string>>(new Set());
  const [hasNextPage, setHasNextPage] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = parseInt(searchParams.get('page') || '1', 10);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const offset = (currentPage - 1) * pageSize;
        const paginatedSql = `${sql} LIMIT ${pageSize + 1} OFFSET ${offset}`;

        const result = await adminApi.executeSelect(paginatedSql);

        setHasNextPage(result.length > pageSize);
        setData(result.slice(0, pageSize));
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [sql, currentPage, pageSize]);

  const goToPage = (page: number) => {
    if (page >= 1) {
      searchParams.set('page', page.toString());
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  };

  const isValidUrl = (string: string): boolean => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const toggleExpand = (cellId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    setExpandedCells((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(cellId)) {
        newSet.delete(cellId);
      } else {
        newSet.add(cellId);
      }
      return newSet;
    });
  };

  const formatDate = (dateString: string): string => {
    const date = parseISO(dateString);
    return format(date, 'yyyy-MM-dd h:mm a');
  };

  const formatValue = (value: any, rowIndex: number, column: string): React.ReactNode => {
    if (value instanceof Date) {
      return formatDate(value.toISOString());
    } else if (typeof value === 'string') {
      if (isValidUrl(value)) {
        return (
          <a
            href={value}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
            className="text-blue-600 hover:text-blue-800 underline"
          >
            Link
          </a>
        );
      } else if (!isNaN(Date.parse(value))) {
        return formatDate(value);
      } else if (value.length > 100) {
        const cellId = `${rowIndex}-${column}`;
        const isExpanded = expandedCells.has(cellId);
        if (isExpanded) {
          return (
            <>
              {value}
              <button
                onClick={(e) => toggleExpand(cellId, e)}
                className="ml-1 text-blue-600 hover:text-blue-800 underline text-xs"
              >
                Less
              </button>
            </>
          );
        } else {
          return (
            <>
              {value.slice(0, 100)}
              <span className="text-gray-500">...</span>
              <button
                onClick={(e) => toggleExpand(cellId, e)}
                className="ml-1 text-blue-600 hover:text-blue-800 underline text-xs"
              >
                More
              </button>
            </>
          );
        }
      }
    }
    return String(value);
  };

  const formatColumnName = (name: string): string => {
    return name.split(/(?=[A-Z])/).join(' ').replace(/\b\w/g, c => c.toUpperCase());
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }

  if (data.length === 0) {
    return <div className="text-center mt-8">No data available.</div>;
  }

  const allColumns = Object.keys(data[0]);
  const visibleColumns = allColumns.filter(column => !hiddenColumns.includes(column));

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      <h1 className="text-2xl font-bold my-4">{title}</h1>

      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <div className="inline-block min-w-full align-middle">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {visibleColumns.map((column) => (
                  <th key={column} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                    {formatColumnName(column)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {visibleColumns.map((column) => (
                    <td
                      key={column}
                      className={`px-6 py-4 whitespace-normal text-sm ${clickHandlers[column] && !isValidUrl(row[column])
                        ? 'text-blue-600 hover:text-blue-800 cursor-pointer hover:bg-gray-100'
                        : 'text-gray-500'
                        }`}
                      onClick={() => clickHandlers[column] && !isValidUrl(row[column]) && clickHandlers[column](column, row)}
                    >
                      {formatValue(row[column], rowIndex, column)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="mt-4 flex justify-between items-center">
        <button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
        >
          Previous
        </button>
        <span>Page {currentPage}</span>
        <button
          onClick={() => goToPage(currentPage + 1)}
          disabled={!hasNextPage}
          className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AdminTable;