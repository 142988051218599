import React from 'react';

const BasicButton: React.FC<{ text: string, onClick: () => void }> = ({ text, onClick }) => (
  <button
    onClick={onClick}
    className="
      bg-skin text-bespokePurple font-bold py-2 px-4 rounded-lg
      hover:bg-skin-400 active:bg-skin-500 text-sm
      transition duration-300 ease-in-out
    "
  >
    {text}
  </button>
);

export default BasicButton;