import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

interface AuthOutletProps {
  isAuthenticated: boolean;
  fallbackPath: string;
}

const AuthOutlet: React.FC<AuthOutletProps> = ({ isAuthenticated, fallbackPath }) => {
  const location = useLocation();
  if (!isAuthenticated) {
    // Redirect to the login page, but save the current location
    sessionStorage.setItem('redirectUrl', location.pathname + location.search);
    return <Navigate to={fallbackPath} state={{ from: location }} replace />;
  }

  // If authenticated, render the child routes
  return <Outlet />;
};

export default AuthOutlet;