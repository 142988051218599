import React, { useState, useEffect } from 'react';
import { UseCaseAddUpdate } from '../../models/UseCase';
import api from '../../api';
import adminApi from '../../admin-api';
import FullPageSpinner from '../FullPageSpinner';
import { useNavigate } from 'react-router-dom';
import validateSchema from './ScriptSchema';
import { CATEGORIES } from '../../models/Category';

interface UseCaseFormProps {
  useCaseId?: string;
  onSave: () => void;
}

const UseCaseForm: React.FC<UseCaseFormProps> = ({ useCaseId, onSave }) => {
  const navigate = useNavigate();

  const [oneOnOne, setOneOnOne] = useState(false);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState(CATEGORIES[0].toLowerCase());
  const [script, setScript] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isJsonValid, setIsJsonValid] = useState(true);
  const [jsonError, setJsonError] = useState('');
  const [adminOnly, setAdminOnly] = useState(false);

  const removeNullValues = (value: any): any => {
    if (Array.isArray(value)) {
      return value.map(removeNullValues).filter(v => v != null);
    } else if (value && typeof value === 'object') {
      return Object.fromEntries(
        Object.entries(value)
          .map(([k, v]) => [k, removeNullValues(v)])
          .filter(([_, v]) => v != null)
      );
    }
    return value;
  };

  useEffect(() => {
    const fetchUseCase = async () => {
      if (useCaseId) {
        setIsLoading(true);
        const useCase = await api.getUseCase(useCaseId);
        setOneOnOne(useCase.oneOnOne);
        setCategory(useCase.category);
        setTitle(useCase.title);
        setScript(JSON.stringify(removeNullValues(useCase.scriptUnprocessed), null, 4));
        setAdminOnly(useCase.adminOnly || false);
        setIsLoading(false);
      }
    };
    fetchUseCase();
  }, [useCaseId]);

  const validateJson = (jsonString: string): boolean => {
    try {
      const parsed = JSON.parse(jsonString);
      const isValid = validateSchema(parsed);
      if (isValid) {
        setIsJsonValid(true);
        setJsonError('');
      } else {
        setIsJsonValid(false);
        let errorMessage = (validateSchema as any).errors?.map((error: any) =>
          `${error.message} at ${error.instancePath || 'root'}`
        ).join('\n');
        setJsonError(errorMessage || 'Invalid JSON schema');
      }
      return isValid;
    } catch (error) {
      setIsJsonValid(false);
      setJsonError(`JSON Syntax Error: ${(error as Error).message}`);
      return false;
    }
  };

  const handleScriptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newScript = e.target.value;
    setScript(newScript);
    validateJson(newScript);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isJsonValid) return;

    setIsLoading(true);
    const useCaseData: UseCaseAddUpdate = {
      oneOnOne,
      category,
      scriptUnprocessed: JSON.parse(script),
      adminOnly,
    };

    if (useCaseId) {
      await adminApi.updateUseCase(useCaseId, useCaseData);
    } else {
      await adminApi.createUseCase(useCaseData);
    }
    navigate(`/admin/use-case?one_on_one=${oneOnOne}&category=${category}`);
    setIsLoading(false);
  };

  if (isLoading) {
    return <FullPageSpinner />
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-lg font-medium text-gray-700">
          {useCaseId && title}
        </label>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          One-on-One
          <input
            type="checkbox"
            checked={oneOnOne}
            onChange={(e) => setOneOnOne(e.target.checked)}
            className="ml-2 form-checkbox h-5 w-5 text-blue-600"
          />
        </label>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Category
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            {CATEGORIES.map((category) => (
              <option key={category.toLowerCase()} value={category.toLowerCase()}>{category}</option>
            ))}
          </select>
        </label>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Script (JSON)
          <textarea
            value={script}
            onChange={handleScriptChange}
            rows={25}
            className={`mt-1 block w-full py-2 px-3 border ${isJsonValid ? 'border-gray-300' : 'border-red-500'
              } bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-mono`}
          />
        </label>
        {!isJsonValid && (
          <pre className="mt-2 text-sm text-red-600 whitespace-pre-wrap">{jsonError}</pre>
        )}
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Admin Only
          <input
            type="checkbox"
            checked={adminOnly}
            onChange={(e) => setAdminOnly(e.target.checked)}
            className="ml-2 form-checkbox h-5 w-5 text-blue-600"
          />
        </label>
      </div>
      <div>
        <button
          type="submit"
          disabled={isLoading || !isJsonValid}
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default UseCaseForm;