export const scrollToTop = () => {
  // Method 1: Using window.scrollTo
  window.scrollTo(0, 0);

  // Method 2: Using scrollY and scroll
  if (window.scrollY) {
    window.scroll(0, 0);
  }

  // Method 3: Scrolling the body and documentElement
  if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  // Method 4: Using scrollIntoView on the topmost element
  const topElement = document.body.firstElementChild;
  if (topElement) {
    topElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

