import React, { useState } from 'react';

interface RecipientPopupProps {
  onClose: () => void;
  onSetRecipientName: (email: string) => void;
  title: string;
}

const RecipientPopup: React.FC<RecipientPopupProps> = ({ onClose, onSetRecipientName, title }) => {
  const [recipientName, setRecipientName] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSetRecipientName(recipientName);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-sm mx-4">
        <h2 className="text-xl font-bold mb-4">{title}</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={recipientName}
            onChange={(e) => setRecipientName(e.target.value)}
            placeholder=""
            className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
            required
          />
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-600 bg-gray-200 rounded-md"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-white bg-blue-500 rounded-md"
            >
              Send Request
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RecipientPopup;