// components/Interview.tsx
import React, { useState, useRef, useEffect, useCallback } from 'react';
import api from '../api';
import InterviewUI, { InterviewUIState } from './InterviewUI';
import InterviewStart from './InterviewStart';
import { useNavigate } from 'react-router-dom';
import { useAirPlay } from '../hooks/useAirPlay';
import FullPageSpinner from './FullPageSpinner';
import { v4 as uuidv4 } from 'uuid';
import EditCommunicationPassAudioRef from './EditCommunicatioPassAudioRef';
import { scrollToTop } from '../utils/scrollToTop';
import { useUserContext } from '../hooks/useUserContext';
import { UseCase } from '../models/UseCase';

interface Question {
  question: string;
  questionAudio: string;
  answer?: string;
  answerAudio?: string;
  answerAudioLength?: number;
}

interface Section {
  title: string;
  questions: Question[];
  skipSummary?: boolean;
  preamble?: string;
  preambleAudio?: string;
  summary?: string;
  summaryAudio?: string;
  summaryQuestion?: Question;
}


enum PageState {
  NotStarted = "NOT_STARTED",
  ReadingPreamble = "READING_PREAMBLE",
  ReadingPaused = "READING_PAUSED",
  RepeatQuestion = "REPEAT_QUESTION",
  ReadingQuestion = "READING_QUESTION",
  RecordingAnswer = "RECORDING_ANSWER",
  GeneratingSummary = "GENERATING_SUMMARY",
  ReadingSummary = "READING_SUMMARY",
  ReadingSummaryQuestion = "READING_SUMMARY_QUESTION",
  RecordingSummaryAnswer = "RECORDING_SUMMARY_ANSWER",
  GeneratingCommunications = "GENERATING_COMMUNICATIONS",
  PollingCommunications = "POLLING_COMMUNICATIONS",
  Finished = "FINISHED",
}

interface InterviewState {
  pageState: PageState;
  actionStarted: boolean;
  currentSection: number;
  currentQuestion: number;
}

const MIME_TYPE = 'audio/mp4';
const REPEAT_AUDIO_URL = "https://bespoke-jesse.s3.us-west-1.amazonaws.com/static/repeat.mp3"

interface InterviewManagerProps {
  communicationRequestId?: string;
  interviewData: {
    id?: string,
    title: string;
    recipient: string;
    otherParticipant?: string;
    sections: Section[];
    communication?: string;
    communicationsAudio?: string;
  };
  oneOnOne: boolean;
  useCase: UseCase;
  onClose: () => void;
}

const InterviewManager: React.FC<InterviewManagerProps> = ({ useCase, onClose, oneOnOne, interviewData: initialInterviewData, communicationRequestId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [communication, setCommunication] = useState<any>(null)
  const [interviewData, setInterviewData] = useState({ ...initialInterviewData, oneOnOne: oneOnOne });
  const [interviewState, setInterviewState] = useState<InterviewState>({ pageState: PageState.NotStarted, actionStarted: false, currentSection: 0, currentQuestion: 0 });
  const [pendingAnswerSaves, setPendingAnswerSaves] = useState(0);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const { audioRef, isAirPlayAvailable, showAirPlayPicker } = useAirPlay()
  const [stateToReturnTo, setStateToReturnTo] = useState<PageState | null>(null)

  const initializeMediaRecorder = async () => {
    console.log('Initializing media recorder');
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    streamRef.current = stream;
    const mediaRecorder = new MediaRecorder(stream, { mimeType: MIME_TYPE });
    mediaRecorderRef.current = mediaRecorder;
    return mediaRecorder
  }

  useEffect(() => {
    return () => {
      cleanupAudio();
      cleanupMediaRecorder();
    };
  }, []);

  function cleanupAudio() {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.src = '';
    }
  }

  function cleanupMediaRecorder() {
    console.log('Cleaning up media recorder');
    if (mediaRecorderRef.current) {
      const mediaRecorder = mediaRecorderRef.current;
      mediaRecorder.ondataavailable = null;
      mediaRecorder.onstop = null;
      mediaRecorderRef.current = null;
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
  }

  const togglePauseReading = () => {
    if (interviewState.pageState === PageState.ReadingPaused) {
      setInterviewState(prevState => {
        const newState = { ...prevState };
        if (stateToReturnTo) {
          newState.pageState = stateToReturnTo;
        }
        return newState;
      });
      if (audioRef.current) {
        // Rewind by 5 seconds, or to the beginning if in the first 5 seconds
        audioRef.current.currentTime = 0; //Math.max(0, audioRef.current.currentTime - 5);
        audioRef.current.play();
      }
    } else {
      setStateToReturnTo(interviewState.pageState)
      setInterviewState(prevState => {
        const newState = { ...prevState };
        newState.pageState = PageState.ReadingPaused;
        return newState;
      });
      audioRef.current?.pause();
    }
  }

  const isSummary = useCallback(() => {
    return interviewState.pageState === PageState.GeneratingSummary ||
      interviewState.pageState === PageState.ReadingSummary ||
      interviewState.pageState === PageState.ReadingSummaryQuestion ||
      interviewState.pageState === PageState.RecordingSummaryAnswer;
  }, [interviewState]);

  const currentSection = useCallback(() => interviewData.sections[interviewState.currentSection], [interviewData, interviewState]);
  const currentQuestion = useCallback(() => currentSection().questions[interviewState.currentQuestion], [currentSection, interviewState]);
  const actionStarted = useCallback(() => {
    setInterviewState(prevState => {
      const newState = { ...prevState };
      newState.actionStarted = true;
      return newState;
    });
  }, []);

  const nextInterviewState = useCallback((reason: String, newPageState?: PageState) => () => {
    console.log('Changing state ' + reason);
    const onLastQuestionInSection = (currentInterviewState: InterviewState) => {
      return currentInterviewState.currentQuestion === interviewData.sections[currentInterviewState.currentSection].questions.length - 1;
    }

    const onLastSection = (currentInterviewState: InterviewState) => {
      return currentInterviewState.currentSection === interviewData.sections.length - 1;
    }

    setInterviewState(prevState => {
      const newState = { ...prevState };
      newState.actionStarted = false;
      if (newPageState) {
        newState.pageState = newPageState;
        return newState
      }
      switch (prevState.pageState) {
        case PageState.NotStarted:
          newState.pageState = PageState.ReadingPreamble;
          break
        case PageState.ReadingPreamble:
          newState.pageState = PageState.ReadingQuestion;
          break;
        case PageState.ReadingQuestion:
          newState.pageState = PageState.RecordingAnswer;
          break;
        case PageState.RepeatQuestion:
          newState.pageState = PageState.ReadingQuestion;
          break;
        case PageState.RecordingAnswer:
          if (onLastQuestionInSection(prevState)) {
            newState.pageState = PageState.GeneratingSummary;
          } else {
            newState.pageState = PageState.ReadingQuestion;
            newState.currentQuestion += 1;
          }
          break;
        case PageState.GeneratingSummary:
          newState.pageState = PageState.ReadingSummary;
          break;
        case PageState.ReadingSummary:
          if (interviewData.sections[newState.currentSection].summaryQuestion) {
            newState.pageState = PageState.ReadingSummaryQuestion;
          } else if (onLastSection(prevState)) {
            newState.pageState = PageState.GeneratingCommunications;
          } else {
            newState.pageState = PageState.ReadingPreamble;
            newState.currentQuestion = 0;
            newState.currentSection += 1;
          }
          break;
        case PageState.ReadingSummaryQuestion:
          newState.pageState = PageState.RecordingSummaryAnswer;
          break;
        case PageState.RecordingSummaryAnswer:
          if (onLastSection(prevState)) {
            newState.pageState = PageState.GeneratingCommunications;
          } else {
            newState.pageState = PageState.ReadingPreamble;
            newState.currentQuestion = 0;
            newState.currentSection += 1;
          }
          break;
        case PageState.GeneratingCommunications:
          newState.pageState = PageState.PollingCommunications;
          break;
        case PageState.PollingCommunications:
          newState.pageState = PageState.Finished;
          break;
      }
      return newState;
    })
  }, [interviewData]);



  const processResponsiveQuestions = async () => {
    if (interviewData.id && interviewState.currentSection < interviewData.sections.length - 1) {
      const sectionNumber = interviewState.currentSection + 1
      const interviewId = interviewData.id
      console.log('Fetching responsive questions')
      const responsiveQuestions = await api.getResponsiveQuestions(interviewData.id, sectionNumber, interviewData)
      console.log('responsive questions ', responsiveQuestions)
      const nextTitle = interviewData.sections[sectionNumber].title
      const section = responsiveQuestions.sections.find((section: Section) => section.title === nextTitle)
      if (!section) {
        console.warn('No responsive questions found for ' + nextTitle)
        return
      }
      // Generate the preamble audio
      const promise = (async () => {
        const audioUrl = await api.generateAudio(interviewId, sectionNumber, -1, section.preamble)
        setInterviewData(prevState => {
          const newState = { ...prevState };
          newState.sections[sectionNumber].preamble = section.preamble;
          newState.sections[sectionNumber].preambleAudio = audioUrl;
          return newState;
        });
        return audioUrl
      })()
      const promises = section.questions.map(async (question: Question, index: number) => {
        const audioUrl = await api.generateAudio(interviewId, sectionNumber, index + 1, question.question)
        console.log("Audio generation complete for question ", index, " ", question.question)
        return audioUrl
      })
      promises.push(promise)
      const audioUrls = await Promise.all(promises)
      audioUrls.pop(); // remove the preamble audio url
      setInterviewData(prevState => {
        const newState = { ...prevState };
        const sectionToChange = newState.sections[sectionNumber]
        section.questions.forEach((question: Question, index: number) => {
          if (index < sectionToChange.questions.length) {
            const questionToChange = sectionToChange.questions[index]
            console.log('In section', sectionToChange.title, ' replacing question ', index, ') ', questionToChange.question, ' with ', question.question)
            questionToChange.question = question.question;
            questionToChange.questionAudio = audioUrls[index];
          } else {
            console.log('In section', sectionToChange.title, ' adding question ', index, ') ', question.question)
            sectionToChange.questions.push({
              question: question.question,
              questionAudio: audioUrls[index]
            });
          }
        })
        return newState;
      })
    }
  }

  useEffect(() => {
    const startRecording = async () => {
      actionStarted();
      cleanupMediaRecorder();
      console.log('Starting recording...');
      const chunks: Blob[] = [];
      const mediaRecorder = await initializeMediaRecorder();
      if (!mediaRecorder) {
        console.error('Media Recorder not initialized');
        return;
      }
      mediaRecorder.ondataavailable = (e) => {
        console.log('ondataavailable ' + e.data.size);
        chunks.push(e.data);
      }
      mediaRecorder.onstop = async () => {
        console.log('onstop ' + chunks.length);
        const audioBlob = new Blob(chunks, { type: MIME_TYPE });
        if (chunks.length === 0 || chunks.every((chunk) => chunk.size === 0)) {
          // re-ask the question.
          // TODO: should probably do this even when there is more then zero data.
          nextInterviewState("Heard Nothing", PageState.RepeatQuestion)();
          return;
        }
        nextInterviewState("Stopped Recording")();
        await submitAnswer(audioBlob, isSummary());
        console.log('on stop finished')
      };
      mediaRecorder.start();
    };

    const askQuestion = () => {
      actionStarted();
      console.log('Asking question')
      let audioSrc = isSummary() ? currentSection().summaryQuestion?.questionAudio : currentQuestion().questionAudio;
      console.log('Asking question ', audioSrc)
      readAudio(audioSrc, "Asking Question Finished");
    };

    const submitAnswer = async (audioBlob: Blob, summary: boolean) => {
      if (!interviewData.id) {
        throw new Error('Should not submit answer without interviewId')
      }
      setPendingAnswerSaves(prevState => prevState + 1)
      try {
        console.log('Submitting answer')
        const response = await api.saveAndTextToSpeech(
          interviewData.id,
          interviewState.currentSection + 1,
          interviewState.currentQuestion + 1,
          summary,
          audioBlob
        );
        console.log('Submitting answer finished')

        setInterviewData(prevState => {
          const newState = { ...prevState };
          const question = summary ? newState.sections[interviewState.currentSection].summaryQuestion : newState.sections[interviewState.currentSection].questions[interviewState.currentQuestion]
          if (question) {
            question.answer = response.text;
            question.answerAudio = response.audioUrl;
            question.answerAudioLength = response.audioLength;
          }
          return newState;
        });
        // add the answer to the interview but don't wait for it to finish
        api.addAnswer(interviewData.id, interviewState.currentSection + 1,
          interviewState.currentQuestion + 1, summary, response.text, response.audioUrl, response.audioLength);
      } finally {
        setPendingAnswerSaves(prevState => prevState - 1)
      }
    };

    const generateSummary = async () => {
      actionStarted();
      processResponsiveQuestions() // start running in the background
      const summary = await api.saveAndSummarize(interviewState.currentSection + 1, interviewData)
      setInterviewData(prevState => {
        const newState = { ...prevState };
        newState.sections[interviewState.currentSection].summary = summary.text;
        newState.sections[interviewState.currentSection].summaryAudio = summary.audioUrl;
        return newState;
      });
      nextInterviewState("Summary Finished")();
    }

    const generateCommunication = async () => {
      actionStarted();
      api.generateCommunication(interviewData, communicationRequestId);
      nextInterviewState("Generate Communications Started")();
    }

    const pollCommunicationStatus = async () => {
      actionStarted();
      let intervalId: any = null;
      let polls = 0;
      const getCommunications = async () => {
        if (!interviewData.id) {
          throw new Error('should not be polling without an interviewId')
        }
        const comms = await api.getCommunication(interviewData.id, true);
        polls += 1;
        console.log('Polling communication status', polls)
        if (comms.status === 'FINISHED') {
          clearInterval(intervalId);
          setCommunication(comms);
          nextInterviewState("Got completed comms")()
          readAudio(comms.audioUrl, "Got completed comms")
        } else if (polls > 600) {
          // give up after 10 minutes
          clearInterval(intervalId);
          console.error('Polling communications timed out');
        }
      }
      intervalId = setInterval(getCommunications, 1000);
    }

    const readAudio = async (audioUrl: string | undefined | null, stateMessage: string) => {
      actionStarted();
      console.log('Reading audio ', audioUrl)
      //cleanupAudio();
      try {
        const next = nextInterviewState(stateMessage);
        if (audioRef.current && audioUrl) {
          const audio = audioRef.current
          audio.src = audioUrl;
          audio.onended = next
          await audio.play()
        } else {
          next();
        }
      } catch (e) {
        console.error('Error reading audio', e)
      }
    }

    const readSummary = async () => {
      readAudio(currentSection().summaryAudio, "Reading Summary Finished");
    };

    const readPreamble = async () => {
      readAudio(currentSection().preambleAudio, "Reading Preamble Finished");
    };

    const repeatQuestion = async () => {
      readAudio(REPEAT_AUDIO_URL, "Reading Repeat Finsihed");
    };

    if (!interviewState.actionStarted) {
      if (interviewState.pageState === PageState.ReadingPreamble) {
        readPreamble();
      } else if (interviewState.pageState === PageState.RepeatQuestion) {
        repeatQuestion();
      } else if (interviewState.pageState === PageState.ReadingQuestion ||
        interviewState.pageState === PageState.ReadingSummaryQuestion) {
        askQuestion();
      } else if (interviewState.pageState === PageState.RecordingAnswer ||
        interviewState.pageState === PageState.RecordingSummaryAnswer) {
        startRecording();
      } else if (interviewState.pageState === PageState.GeneratingSummary) {
        if (currentSection().skipSummary) {
          nextInterviewState("Skipping Summary")();
        } else if (pendingAnswerSaves === 0) {
          generateSummary();
        }
      } else if (interviewState.pageState === PageState.ReadingSummary) {
        readSummary();
      } else if (interviewState.pageState === PageState.GeneratingCommunications) {
        if (pendingAnswerSaves === 0) {
          generateCommunication();
        }
      } else if (interviewState.pageState === PageState.PollingCommunications) {
        pollCommunicationStatus();
      }
    }
  }, [interviewState, interviewData, pendingAnswerSaves, currentQuestion, currentSection, nextInterviewState, isSummary, actionStarted]);

  useEffect(() => {
    console.log('Interview State Updated:');
    console.log(JSON.stringify(interviewState, null, 2));
  }, [interviewState]);

  const startInterview = async (otherParticipant?: string) => {
    scrollToTop()
    setIsLoading(true)
    try {
      await initializeMediaRecorder();
      const id = uuidv4()
      api.createNewInterview(id, useCase.id, otherParticipant)
      setInterviewData(prevData => {
        return { ...prevData, id, otherParticipant }
      })
    } finally {
      setIsLoading(false)
    }
    nextInterviewState("Starting Interview")();
  }

  const stopRecording = () => {
    console.log('Stopping recording...');
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  };

  const skipSpeaking = () => {
    console.log('Skip Speaking recording...');
    if (audioRef.current) {
      const audio = audioRef.current
      audio.pause();
    }
    nextInterviewState("Skip Speaking")();
  };

  const speakingStates = [
    PageState.ReadingPreamble, PageState.ReadingQuestion,
    PageState.ReadingSummary, PageState.ReadingSummaryQuestion,
    PageState.RepeatQuestion
  ];

  const listeningStates = [PageState.RecordingAnswer, PageState.RecordingSummaryAnswer];

  const processingStates = [
    PageState.GeneratingSummary, PageState.GeneratingCommunications,
    PageState.PollingCommunications
  ];
  const pausedStates = [
    PageState.ReadingPaused,
  ];

  const onBack = () => {
    console.log('onBack')
    // right now we only support back from listening states
    if (listeningStates.includes(interviewState.pageState)) {
      cleanupMediaRecorder();
      setInterviewState(prevState => {
        const newState = { ...prevState };
        newState.actionStarted = false;
        if (newState.pageState === PageState.RecordingAnswer) {
          newState.pageState = PageState.ReadingQuestion;
        } else if (newState.pageState === PageState.RecordingSummaryAnswer) {
          newState.pageState = PageState.ReadingSummaryQuestion;
        }
        return newState;
      });
    } else {
      console.warn('onBack called from non-listening state', interviewState.pageState)
    }
  }

  const interviewUIState = (): InterviewUIState => {

    if (speakingStates.includes(interviewState.pageState)) {
      return InterviewUIState.SPEAKING;
    } else if (listeningStates.includes(interviewState.pageState)) {
      return InterviewUIState.LISTENING;
    } else if (processingStates.includes(interviewState.pageState)) {
      return InterviewUIState.PROCESSING;
    } else if (pausedStates.includes(interviewState.pageState)) {
      return InterviewUIState.PAUSED;
    } else {
      return InterviewUIState.PROCESSING
    }
  }
  let processingText = ''
  if (interviewState.pageState === PageState.GeneratingCommunications || interviewState.pageState === PageState.PollingCommunications) {
    processingText = 'Creating Script (1-2 minutes)'
  }


  const title = currentSection().title;
  const uiState = interviewUIState();

  return (
    <>
      <audio ref={audioRef} />
      {isLoading ? <FullPageSpinner /> :
        interviewState.pageState === PageState.NotStarted ? (
          <InterviewStart useCase={useCase} recipient={interviewData.recipient} otherParticipant={interviewData.otherParticipant} oneOnOne={oneOnOne} title={interviewData.title} onStart={startInterview} onClose={onClose} />
        ) :
          interviewState.pageState === PageState.Finished ? (
            <EditCommunicationPassAudioRef communication={communication} audioRef={audioRef} startsPlaying={true} />
          ) :
            <InterviewUI onClose={onClose} title={title} state={uiState} onSkipSpeaking={skipSpeaking}
              onStopRecording={stopRecording}
              isAirPlayAvailable={isAirPlayAvailable}
              showAirPlayPicker={showAirPlayPicker}
              togglePauseReading={togglePauseReading}
              onBack={onBack}
              processingText={processingText} />}
    </>
  );
};

export default InterviewManager;