import React, { useState } from 'react';
import ChooseUseCase from './ChooseUseCase';
import ChooseUseCaseStep2 from './ChooseUseCaseStep2';
import User from '../models/User';
import { useNavigate } from 'react-router-dom';

const Main: React.FC = () => {
  const navigate = useNavigate();
  const [oneOnOne, setOneOnOne] = useState<boolean | undefined>(undefined);

  const handleSelectUseCase = async (useCaseId: string) => {
    navigate(`/interview?useCaseId=${useCaseId}&oneOnOne=${oneOnOne}`);
  };

  const onClose = () => {
    setOneOnOne(undefined);
  }

  return (
    <>
      {oneOnOne === undefined && (<ChooseUseCase onSelectOneToOne={setOneOnOne} />)}
      {oneOnOne !== undefined && (<ChooseUseCaseStep2 oneOnOne={oneOnOne} onClose={onClose} onSelectUseCaseId={handleSelectUseCase} />)}
    </>
  );
};

export default Main;