import React from 'react';

const GradientBespoke: React.FC = () => {
  return (
    <>
      <h1 className="text-3xl sm:text-4xl font-bold">
        <span className="text-transparent bg-clip-text bg-gradient-to-r from-bespokePurple via-bespokePink to-bespokeOrange">
          beSpoke
        </span>
      </h1>
      <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold">
        <span className="text-transparent bg-clip-text bg-gradient-to-r from-bespokePurple via-bespokePink to-bespokeOrange">
          communications
        </span>
      </h1>
    </>);
};

export default GradientBespoke;