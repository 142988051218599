import React, { useState, useEffect, useRef } from 'react';

const AudioTest: React.FC = () => {
  const [volume, setVolume] = useState<number>(0);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const dataArrayRef = useRef<Uint8Array | null>(null);
  const lastVolumeRef = useRef<number>(0);
  const rafRef = useRef<number | null>(null);

  useEffect(() => {
    const initializeAudio = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioContextRef.current = new AudioContext();
        analyserRef.current = audioContextRef.current.createAnalyser();
        const source = audioContextRef.current.createMediaStreamSource(stream);
        source.connect(analyserRef.current);
        analyserRef.current.fftSize = 32;
        const bufferLength = analyserRef.current.frequencyBinCount;
        dataArrayRef.current = new Uint8Array(bufferLength);
      } catch (error) {
        console.error('Error initializing audio:', error);
      }
    };

    initializeAudio();

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const updateVolume = () => {
      if (analyserRef.current && dataArrayRef.current) {
        analyserRef.current.getByteFrequencyData(dataArrayRef.current);
        const average = dataArrayRef.current.reduce((a, b) => a + b) / dataArrayRef.current.length;

        // Only update if the volume has changed significantly
        if (Math.abs(average - lastVolumeRef.current) > 2) {
          setVolume(average);
          lastVolumeRef.current = average;
        }
      }
      rafRef.current = requestAnimationFrame(updateVolume);
    };

    updateVolume();
  }, []);

  const maxScale = 1.5;
  const minScale = 0.3;
  const scale = minScale + (volume / 255) * (maxScale - minScale);

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <img
        src="/img/main-logo.png" // Consider using WebP format
        alt="Main Logo"
        className="transition-transform duration-50 will-change-transform"
        style={{
          transform: `translate3d(0, 0, 0) scale(${scale})`,
        }}
      />
    </div>
  );
};

export default AudioTest;