import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import GradientBespoke from './GradientBespoke';
import NavigationMenu from './NavigationMenu';
import { useUserContext } from '../hooks/useUserContext';
import api from '../api';

const ChooseUseCase: React.FC<{ onSelectOneToOne: (oneToOne: boolean) => void }> = ({ onSelectOneToOne }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const { user } = useUserContext()

  useEffect(() => {
    const hasSeenVideo = localStorage.getItem('hasSeenVideo') === 'true';
    if (!hasSeenVideo && !user.hasSeenVideo) {
      localStorage.setItem('hasSeenVideo', 'true');
      api.updateUser({ hasSeenVideo: true })
      setShowVideo(true);
    }
  }, []);

  const buttonClassNames = `
    bg-white text-black text-xl font-bold py-2 px-4 rounded-lg w-full 
    hover:bg-gray-100 hover:shadow-md 
    active:bg-gray-200 active:shadow-inner 
    transition duration-150 ease-in-out
  `;

  const closeVideo = () => {
    setShowVideo(false);
    setIsVideoReady(false);
  };

  const videoOpts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 0,
      origin: window.location.origin
    },
  };

  const onVideoError = (event: { data: number }) => {
    console.error('YouTube player error:', event.data);
    // You can add more detailed error handling here
  };

  const onVideoReady = (event: { target: any }) => {
    setIsVideoReady(true);
  };

  return (
    <div className="h-screen-dynamic flex flex-col items-center justify-center bg-white relative">
      <NavigationMenu />

      <div className="flex-grow flex flex-col items-center justify-center w-full max-w-3xl relative pt-12">
        <img
          src="img/main-logo.png"
          alt="Logo"
          className="w-48 cursor-pointer"
        />
        <p className="text-center text-sm text-bespokePink mt-4">Get started with</p>
        <div className="text-center mt-4"><div className="text-center mb-8"><GradientBespoke /></div></div>
      </div>

      <div className="w-full h-64 bg-skin text-center rounded-t-3xl relative overflow-hidden flex items-center justify-center">
        <div className="flex flex-col space-y-4 w-64">
          <p className="text-sm">What type of communication are you preparing for?</p>
          <button className={buttonClassNames} onClick={() => { onSelectOneToOne(true) }}>With an Individual</button>
          <button className={buttonClassNames} onClick={() => { onSelectOneToOne(false) }}>With a Group</button>
        </div>
      </div>

      {showVideo && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-black w-full max-w-2xl">
            <div className="w-full bg-black flex justify-end items-center h-12">
              <button
                onClick={closeVideo}
                className="text-white text-2xl font-bold px-4 h-full hover:bg-gray-900 transition-colors"
              >
                ×
              </button>
            </div>
            <div className="text-white text-center py-4">
              <p className="text-xl">
                Click play to learn about Bespoke AI
              </p>
            </div>
            <div className="relative w-full h-[60vh] md:h-[70vh]">
              {!isVideoReady && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
                </div>
              )}
              <div className={`w-full h-full transition-opacity duration-300 ${isVideoReady ? 'opacity-100' : 'opacity-0'}`}>
                <YouTube
                  videoId="wWdwc5Vj-Iw"
                  opts={videoOpts}
                  onReady={onVideoReady}
                  onError={onVideoError}
                  onEnd={closeVideo}
                  className="w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChooseUseCase;