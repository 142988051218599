import React, { useState, useEffect } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

interface ToastProps {
  message: string;
  type: 'success' | 'error';
  duration?: number;
  onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ message, type, duration = 3000, onClose }) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [shouldRender, setShouldRender] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, duration - 300);

    return () => clearTimeout(timer);
  }, [duration]);

  useEffect(() => {
    if (!isVisible) {
      const timer = setTimeout(() => {
        setShouldRender(false);
        onClose();
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  if (!shouldRender) return null;

  const iconColor = type === 'success' ? 'text-green-500' : 'text-red-500';
  const Icon = type === 'success' ? FaCheckCircle : FaTimesCircle;

  return (
    <div className="fixed top-4 left-0 right-0 flex justify-center items-center z-50">
      <div
        className={`flex items-center bg-white border border-gray-200 rounded-lg shadow-md p-4 max-w-sm mx-4 transition-opacity duration-300 ease-in-out ${isVisible ? 'opacity-100' : 'opacity-0'
          }`}
      >
        <Icon className={`text-2xl ${iconColor} mr-3`} />
        <p className="text-gray-800">{message}</p>
      </div>
    </div>
  );
};

export default Toast;