import React from 'react';
import BasicButton from './BasicButton';

interface XToOnePopupProps {
  onClose: () => void;
  setOneOnOne: (value: boolean) => void;
  email: string;
}

const OneToXPopup: React.FC<XToOnePopupProps> = ({ onClose, email, setOneOnOne }) => {

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-sm mx-4">
        <h2 className="text-2xl font-bold mb-4 text-center">What type of communication</h2>
        <p className="text-center mb-6">should {email} prepare for?</p>
        <div className="flex flex-col space-y-4">
          <BasicButton text="With an individual" onClick={() => setOneOnOne(true)} />
          <BasicButton text="With a group" onClick={() => setOneOnOne(false)} />
        </div>
      </div>
    </div>
  );
};

export default OneToXPopup;