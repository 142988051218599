import React, { useState } from 'react';
import GradientBespoke from './GradientBespoke';
import FullPageSpinner from './FullPageSpinner';
import api from '../api';

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const handleGoogleLogin = async (): Promise<void> => {
    setLoading(true);
    window.location.href = await api.getGoogleAuthorizationUrl()
  };

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <div className="min-h-screen flex flex-col">
      {/* Top section with skin background */}
      <div className="bg-gradient-to-b from-skin-200 to-skin-400 flex-grow pb-16">
        <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12 py-8 sm:py-12">
          <div className="flex flex-col md:flex-row md:items-stretch">
            <div className="md:w-1/2 md:pr-8 flex flex-col">
              <div>
                <div className="flex items-center mb-6">
                  <img className="h-12 sm:h-16 w-auto mr-4" src="img/main-logo.png" alt="beSpoke logo" />
                  <div className="text-left ml-2">
                    <GradientBespoke />
                  </div>
                </div>
                <p className="mt-4 text-xl sm:text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-bespokePurple via-bespokePink to-bespokeOrange">
                  Crisp Communications for High Stakes Situations
                </p>
                <p className="mt-6 text-base sm:text-lg leading-7 sm:leading-8 text-gray-700">
                  Developing effective communications is hard and time consuming. beSpoke is like a virtual communications department for teams who can’t afford one.

                  beSpoke’s guided interviews translate unorganized thoughts into first draft scripts. This saves time and improves the quality of communications
                </p>
              </div>
              <div className="flex-grow flex flex-col justify-center min-h-[100px] pt-8">
                <div className="flex justify-center">
                  <button
                    onClick={handleGoogleLogin}
                    className="flex items-center px-6 py-3 bg-white border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-100 active:bg-gray-200 active:shadow-inner transition duration-150 ease-in-out shadow-md"
                  >
                    <img src="img/google.png" alt="Google Logo" className="w-6 h-6 mr-4" />
                    <span className="font-semibold">Sign up / Log in with Google</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 mt-8 md:mt-0 flex items-center">
              <img src="img/people.webp" alt="People using beSpoke" className="w-full h-auto rounded-lg shadow-lg" />
            </div>
          </div>
        </div>
      </div>

      {/* Lower sections overlapping with top region */}
      <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12 -mt-16">
        <div className="bg-gradient-to-br from-skin-50 to-skin-100 rounded-lg shadow-lg overflow-hidden">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-8">
            {/* HR Communications */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl sm:text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-bespokePurple to-bespokePink mb-4">HR Communications</h2>
              <p className="text-base sm:text-lg text-gray-700 mb-4">
                Efficiently collaborate with business partners. beSpoke gathers information from business partners and creates compliant first draft scripts for you to approve.
              </p>
              <h3 className="text-lg sm:text-xl font-semibold text-bespokePurple mb-2">Sample HR Use Cases</h3>
              <ul className="list-disc list-inside text-sm sm:text-base text-gray-600 space-y-1">
                <li>PIPs</li>
                <li>Terminations</li>
                <li>Exit Interviews</li>
                <li>Layoffs</li>
                <li>Orientation for New Employees</li>
              </ul>
            </div>

            {/* Business Communications */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl sm:text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-bespokePink to-bespokeOrange mb-4">Business Communications</h2>
              <p className="text-base sm:text-lg text-gray-700 mb-4">
                Effortlessly translate unformulated ideas into crisp 1st drafts, spoken in your voice so you can anticipate how your message will land.
              </p>
              <h3 className="text-lg sm:text-xl font-semibold text-bespokeOrange mb-2">Business Use Cases</h3>
              <ul className="list-disc list-inside text-sm sm:text-base text-gray-600 space-y-1">
                <li>Updates to Execs or Board</li>
                <li>Feedback</li>
                <li>Crisis Management</li>
                <li>Keynote Speeches</li>
                <li>Elevator Pitches</li>
                <li>Retros</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-white mt-6 mb-6">
        {/* Terms and Privacy links */}
        <div className="bottom-0 left-0 w-full p-2 text-center text-xs">
          <a href="/privacy.html" className="text-gray-500 hover:text-gray-700">Privacy</a>
          <span className="mx-1 text-gray-500">|</span>
          <a href="/terms.html" className="text-gray-500 hover:text-gray-700">Terms</a>
        </div>
      </footer>
    </div>
  );
};

export default Login;