import React from 'react';
import { Outline } from '../api';
import api from '../api';

interface CommunicationOutlineProps {
  outline: Outline | undefined;
  communicationId: string;
}

const CommunicationOutline: React.FC<CommunicationOutlineProps> = ({ outline, communicationId }) => {
  const renderOutlineItems = (items: Outline['subItems']) => {
    if (!items) return null;
    return (
      <ul className="list-disc pl-5 space-y-2">
        {items.map((item, index) => (
          <li key={index} style={{ marginTop: '0px' }}>
            {item.text}
            {item.subItems && item.subItems.length > 0 &&
              renderOutlineItems(item.subItems)}
          </li>
        ))}
      </ul>
    );
  };

  const handleDownloadPPTX = async () => {
    const response = await api.generatePPTX(communicationId);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'interview_summary.pptx';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="flex-grow w-full py-4">
      {outline && (
        <div className="space-y-2">
          {renderOutlineItems(outline.subItems)}
        </div>
      )}
      <button
        onClick={handleDownloadPPTX}
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
      >
        Download PowerPoint
      </button>
    </div>
  );
};

export default CommunicationOutline;