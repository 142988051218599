import React from 'react';

const Spinner: React.FC = () => {
  return (
    <div className="w-16 h-16 border-4 border-red-200 rounded-full flex items-center justify-center">
      <div className="w-16 h-16 border-4 border-t-4 border-t-red-500 border-transparent rounded-full animate-spin"></div>
    </div>
  );
}

export default Spinner;