import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout: React.FC<{ handleLogout: () => void }> = ({ handleLogout }) => {
  const navigate = useNavigate()
  useEffect(() => {
    handleLogout()
    navigate('/login')
  }, []);

  return null;
}

export default Logout;
