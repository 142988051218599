import { useState, useEffect, useRef } from 'react';

export const useAirPlay = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isAirPlayAvailable, setIsAirPlayAvailable] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    setIsLoaded(true);
  }, [])

  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    const audio = audioRef.current;
    if (audio && 'WebKitPlaybackTargetAvailabilityEvent' in window) {
      const handleAvailabilityChange = (event: any) => {
        setIsAirPlayAvailable(event.availability === 'available');
      };

      audio.addEventListener('webkitplaybacktargetavailabilitychanged', handleAvailabilityChange);

      return () => {
        audio.removeEventListener('webkitplaybacktargetavailabilitychanged', handleAvailabilityChange);
      };
    }
  }, [isLoaded, audioRef]);

  const showAirPlayPicker = () => {
    if (audioRef.current && 'webkitShowPlaybackTargetPicker' in audioRef.current) {
      (audioRef.current as any).webkitShowPlaybackTargetPicker();
    }
  };

  return { audioRef, isAirPlayAvailable, showAirPlayPicker };
};