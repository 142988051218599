import React, { useEffect, useState } from 'react';
import { parseISO, format } from 'date-fns';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FullPageSpinner from './FullPageSpinner';
import Header from './Header';
import BasicButton from './BasicButton';
import User from '../models/User';
import Badge from './Badge';
import EmailPopup from './EmailPopup';
import OneToXPopup from './OneToXPopup';
import ChooseUseCaseStep2 from './ChooseUseCaseStep2';
import Toast from './Toast';
import RecipientPopup from './RecipientPopup';
import api from '../api';
import { CommunicationRequestsAll } from '../models/CommunicationRequest';
import CommunicationRequest from '../models/CommunicationRequest';
import { useUserContext } from '../hooks/useUserContext';


const RequestFooter: React.FC<{ request: CommunicationRequest, user: User }> = ({ request, user }) => {
  if (request.recipientEmail === user.email) {
    return (
      <p className="text-sm text-gray-500">
        Requested by: <span className='italic'>{request.senderEmail}</span> | {format(parseISO(request.created), 'dd MMM yyyy')}
      </p>
    )
  }
  return (
    <p className="text-sm text-gray-500">
      Requested of: <span className='italic'>{request.recipientEmail}</span> | {format(parseISO(request.created), 'dd MMM yyyy')}
    </p>
  )
}

const Requests: React.FC = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [allRequests, setAllRequests] = useState<CommunicationRequestsAll>({ pending: [], sent: [], completed: [] });
  const [completedUnviewCount, setCompletedUnviewCount] = useState<number>(0);
  const [showNewRequestPopup, setShowNewRequestPopup] = useState(false);
  const [showOneToXPopup, setShowOneToXPopup] = useState(false);
  const [oneOnOne, setOneOnOne] = useState<boolean | undefined>(undefined);
  const [showChooseCategoryPopup, setShowChooseCategoryPopup] = useState<boolean>(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState<boolean>(false);
  const [showRecipientPopup, setShowRecipientPopup] = useState<boolean>(false);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [useCaseId, setUseCaseId] = useState<string | undefined>(undefined);
  const [recipient, setRecipient] = useState<string | undefined>(undefined);
  const [activeTab, setActiveTab] = useState(() => {
    return searchParams.get('activeTab') || 'pending';
  });
  const pendingCount = allRequests.pending.length;

  useEffect(() => {
    const fetchRequests = async () => {
      const allRequests = await api.getCommunicationRequests();
      console.log('allRequests', allRequests);
      setLoading(false);
      setAllRequests(allRequests);
    }
    fetchRequests()
  }, [])

  useEffect(() => {
    const fetchCounts = async () => {
      const counts = await api.getCommunicationRequestCounts();
      setCompletedUnviewCount(counts.completedUnviewCount);
    }
    fetchCounts()
  }, [])

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('activeTab', activeTab);
    setSearchParams(newSearchParams);
  }, [activeTab, setSearchParams, searchParams]);

  const buttonText = (): string => {
    switch (activeTab) {
      case 'pending':
        return 'Start Now';
      case 'sent':
        return 'Remind';
      default:
        return 'View';
    }
  }

  const handleEnterEmail = (email: string) => {
    setEmail(email);
    setShowNewRequestPopup(false);
    setShowOneToXPopup(true);
  }

  const handleOneToOne = (value: boolean) => {
    setOneOnOne(value);
    setShowOneToXPopup(false);
    setShowChooseCategoryPopup(true);
  }

  const handleSelectUseCase = (theUseCaseId: string, recipient?: string) => {
    setShowChooseCategoryPopup(false);
    if (oneOnOne) {
      setUseCaseId(theUseCaseId)
      setRecipient(recipient);
      setShowRecipientPopup(true);
      return
    } else {
      createRequest(theUseCaseId)
    }
  }

  const handleRecipientName = (recipientName: string) => {
    setShowRecipientPopup(false);
    createRequest(useCaseId, recipientName);
  }

  const createRequest = async (theUseCaseId: string | undefined, recipientName?: string) => {
    if (!email || oneOnOne === undefined || !theUseCaseId) {
      return;
    }
    setLoading(true);
    await api.createCommunicationRequest({
      recipientEmail: email, useCaseId: theUseCaseId, oneOnOne: oneOnOne,
      communicationRecipientName: recipientName
    });
    setAllRequests(await api.getCommunicationRequests());
    setShowConfirmationPopup(true);
    setActiveTab('sent');
    setLoading(false);
  }

  const handleRequestClick = (request: CommunicationRequest) => async () => {
    if (activeTab === 'pending') {
      navigate(`/interview?useCaseId=${request.useCaseId}&oneOnOne=${request.oneOnOne}&recipientName=${request.communicationRecipientName}&communicationRequestId=${request.id}`);
    } else if (activeTab === 'completed') {
      if (!request.viewed && request.senderUserId === user.id) {
        setLoading(true);
        await api.markCommunicationRequestAsViewed(request.id);
      }
      navigate(`/communication/${request.communicationId}`);
    } else {
      setLoading(true);
      setEmail(request.recipientEmail);
      await api.remindCommunicationRequest(request.id);
      setShowConfirmationPopup(true);
      setLoading(false);
    }
  }

  const requests = activeTab === 'pending' ? allRequests.pending :
    activeTab === 'sent' ? allRequests.sent : allRequests.completed;

  if (loading) {
    return <FullPageSpinner />
  }

  return (
    <>
      {!showChooseCategoryPopup &&
        <div className="flex flex-col items-center min-h-screen bg-white">
          <div className="w-full max-w-3xl bg-white">
            <Header title="Requests" onBack={() => { navigate('/') }} onHeaderButton={() => { setShowNewRequestPopup(true) }} headerButtonText="New Request" />

            {/* Tab Menu */}
            <div className="flex border-b border-gray-300">
              <button
                className={`flex-1 py-2 px-4 font-medium ${activeTab === 'pending' ? 'text-bespokePurple border-b-2 border-bespokePurple' : 'text-gray-500'}`}
                onClick={() => setActiveTab('pending')}
              >
                Pending
                <Badge count={pendingCount} />
              </button>
              <button
                className={`flex-1 py-2 px-4 font-medium ${activeTab === 'sent' ? 'text-bespokePurple border-b-2 border-bespokePurple' : 'text-gray-500'}`}
                onClick={() => setActiveTab('sent')}
              >
                Sent
              </button>
              <button
                className={`flex-1 py-2 px-4 font-medium ${activeTab === 'completed' ? 'text-bespokePurple border-b-2 border-bespokePurple' : 'text-gray-500'}`}
                onClick={() => setActiveTab('completed')}
              >
                Completed
                <Badge count={completedUnviewCount} />
              </button>
            </div>

            <div className="flex flex-col divide-y divide-gray-300">
              {requests.map((request, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center p-4 cursor-pointer"
                >
                  <div className="ml-2">
                    <p className={`m-1 text-gray-800`}>
                      <span className={!request.viewed ? 'font-semibold' : ''}>{request.useCaseTitle}</span> with <span className={!request.viewed ? 'font-semibold' : ''}>{request.communicationRecipientName}</span>
                    </p>
                    <RequestFooter request={request} user={user} />
                  </div>
                  <BasicButton text={buttonText()} onClick={handleRequestClick(request)} />
                </div>
              ))}
            </div>
          </div>
          {showNewRequestPopup && <EmailPopup buttonText="Next" title="Who should be interviewed?" onClose={() => setShowNewRequestPopup(false)} onEnterEmail={handleEnterEmail} />}
          {showOneToXPopup && email && <OneToXPopup setOneOnOne={handleOneToOne} onClose={() => setShowOneToXPopup(false)} email={email} />}
          {showRecipientPopup && email && <RecipientPopup title={`Name of ${recipient}`} onSetRecipientName={handleRecipientName} onClose={() => setShowRecipientPopup(false)} />}
          {showConfirmationPopup && email && <Toast onClose={() => setShowConfirmationPopup(false)} message={`Request sent to ${email}`} type='success' />}
        </div>
      }
      {showChooseCategoryPopup && oneOnOne !== undefined &&
        <ChooseUseCaseStep2
          email={email}
          oneOnOne={oneOnOne}
          onClose={() => { setShowChooseCategoryPopup(false) }}
          onSelectUseCaseId={handleSelectUseCase}
        />
      }
    </>
  );
}

export default Requests;