import React, { useEffect, useState } from 'react';
import api, { AllCommunication } from '../api';
import { parseISO, format } from 'date-fns';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FullPageSpinner from './FullPageSpinner';
import { FaChevronRight } from 'react-icons/fa';
import Header from './Header';
import Badge from './Badge';

const Communications = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [sharedCount, setSharedCount] = useState(0);
  const [allCommunications, setAllCommunications] = useState<AllCommunication>({ communications: [], sharedCommunications: [] });

  // Initialize isShared from URL params, defaulting to false if not present
  const [isShared, setIsShared] = useState(() => {
    return searchParams.get('shared') === 'true';
  });

  useEffect(() => {
    const fetchShareCount = async () => {
      const shareCount = await api.getCommunicationShareCounts();
      setSharedCount(shareCount.shareCount);
    };
    fetchShareCount();
  }, []);

  useEffect(() => {
    const fetchCommunications = async () => {
      const all = await api.fetchCommunications();
      setAllCommunications(all);
      setLoading(false);
    };

    fetchCommunications();
  }, []);

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('shared', isShared.toString());
    setSearchParams(newSearchParams);
  }, [isShared, setSearchParams, searchParams]);

  const toggleShared = (newIsShared: boolean) => {
    setIsShared(newIsShared);
  };

  if (loading) {
    return <FullPageSpinner />
  }

  const communications = isShared ? allCommunications.sharedCommunications : allCommunications.communications;

  return (
    <div className="flex flex-col items-center min-h-screen bg-white">
      <div className="w-full max-w-3xl bg-white">
        <Header title="Communications" onBack={() => { navigate('/') }} />

        {/* Tab Menu */}
        <div className="flex border-b border-gray-300">
          <button
            className={`flex-1 py-2 px-4 font-medium ${!isShared ? 'text-bespokePurple border-b-2 border-bespokePurple' : 'text-gray-500'}`}
            onClick={() => toggleShared(false)}
          >
            Mine
          </button>
          <button
            className={`flex-1 py-2 px-4 font-medium ${isShared ? 'text-bespokePurple border-b-2 border-bespokePurple' : 'text-gray-500'}`}
            onClick={() => toggleShared(true)}
          >
            Shared with Me
            <Badge count={sharedCount} />
          </button>
        </div>

        <div className="flex flex-col divide-y divide-gray-300">
          {communications.map((communication, index) => (
            <div
              key={index}
              className="flex justify-between items-center p-4 hover:bg-gray-100 cursor-pointer"
              onClick={() => navigate(`/communication/${communication.id}`)}
            >
              <div className="ml-2">
                <p className="m-1 font-semibold text-gray-800">{communication.title}</p>
                <p className={`text-sm text-gray-500 ${isShared && !communication.viewed ? 'font-bold' : ''}`}>
                  {`${communication.oneOnOne ? 'Individual' : 'Group'} | ${format(parseISO(communication.created), 'dd MMM yyyy')}`}
                </p>
                <p className={`italic text-sm text-gray-500 ${isShared && !communication.viewed ? 'font-bold' : ''}`}>
                  {`${communication.text.substring(0, 100)}...`}
                </p>
              </div>
              <FaChevronRight className="text-gray-500" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Communications;