import React from 'react';
import Spinner from './Spinner'; // Import your existing Spinner component

const FullPageSpinner: React.FC = () => {
  return (
    <div className="h-screen-dynamic flex flex-col items-center justify-center bg-white">
      <Spinner />
    </div>
  );
}

export default FullPageSpinner;