import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import UseCaseForm from './UseCaseForm';
import NavigationMenu from '../NavigationMenu';

const EditUseCasePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const handleSave = () => {
    navigate('/admin'); // Or wherever your admin page is
  };

  return (
    <>
      <NavigationMenu />
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-2xl font-bold my-4">{id ? 'Edit' : 'New'} Use Case</h1>
        <UseCaseForm useCaseId={id} onSave={handleSave} />
      </div>
    </>
  );
};

export default EditUseCasePage;