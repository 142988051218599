import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AdminTable from './AdminTable';
import NavigationMenu from '../NavigationMenu';

const CommunicationAdmin: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const emailFilter = searchParams.get('email');

  const sql = `
      SELECT 
        c.id,
        c.title,
        u.email,
        c.status,
        c.original_text,
        c.created,
        'communication' as type
      FROM 
        communication c 
          LEFT OUTER JOIN user u ON u.id = c.user_id 
      ${emailFilter ? `WHERE u.email = '${emailFilter}'` : ''}
      UNION
      SELECT 
        i.id,
        JSON_UNQUOTE(JSON_EXTRACT(uc.script, '$.title')) as title,
        u.email,
        'PARTIALLY COMPLETED' as status,
        null as original_text,
        i.created,
        'interview' as type
      FROM 
        interview i 
          JOIN user u ON u.id = i.user_id 
          JOIN use_case uc ON uc.id = i.use_case_id 
        where
          not exists (select 'X' from communication c2 where c2.interview_id = i.id)
          ${emailFilter ? `and u.email = '${emailFilter}'` : ''}
      ORDER BY 
        created DESC
  `;

  const handleEmailClick = (columnName: string, rowData: any) => {
    const newEmailFilter = rowData.email === emailFilter ? null : rowData.email;

    if (newEmailFilter) {
      navigate(`/admin/communication?email=${encodeURIComponent(newEmailFilter)}`);
    } else {
      navigate('/admin/communication');
    }
  };

  const handleTitleClick = (columnName: string, rowData: any) => {
    if (rowData.type === 'interview') {
      navigate(`/admin/communication/${rowData.id}?interviewId=true`);
    } else {
      navigate(`/admin/communication/${rowData.id}`);
    }
  };

  const clickHandlers = {
    email: handleEmailClick,
    title: handleTitleClick,
  };

  const title = emailFilter
    ? `Communications for ${emailFilter}`
    : "Recent Communications";

  return (
    <>
      <NavigationMenu />
      <AdminTable
        sql={sql}
        title={title}
        clickHandlers={clickHandlers}
        hiddenColumns={['id', 'type']}
      />
    </>
  );
};

export default CommunicationAdmin;