import React from 'react';

interface AdjustAudioProps {
  isAirPlayAvailable: boolean;
  showAirPlayPicker: () => void;
}

const AdjustAudio: React.FC<AdjustAudioProps> = ({ isAirPlayAvailable, showAirPlayPicker }) => {
  if (!isAirPlayAvailable) {
    return null;
  }
  return (
    <div className="w-full max-w-3xl px-4 flex justify-start mt-2">
      <button
        onClick={showAirPlayPicker}
        className="flex items-center text-left text-gray-600 hover:text-gray-800 text-sm"
      >
        <img src="img/airplay.svg" alt="AirPlay" className="w-6 h-6 mr-2" />
        Adjust Audio
      </button>
    </div>
  );
}

export default AdjustAudio;