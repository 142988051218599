import React from 'react';
import { UseCase } from '../models/UseCase';
import { approximateMinutesUseCase, approximateMinutes } from '../utils/timeEstimate';

interface InterviewOutlineProps {
  useCase: UseCase;
}

const InterviewOutline: React.FC<InterviewOutlineProps> = ({ useCase }) => {
  const totalTime = approximateMinutesUseCase(useCase);
  const sections = useCase.script.sections || [];

  return (
    <div className="mt-8 bg-gray-50 rounded-lg shadow-sm p-6">
      <h2 className="text-xl font-semibold mb-2 text-gray-800">Interview Outline</h2>
      <ul className="space-y-3">
        {sections.map((section: any, index: number) => (
          <li key={index} className="flex items-center justify-between py-2 border-b border-gray-200 last:border-b-0">
            <span className="text-gray-700">{section.title}</span>
            <span className="text-sm font-medium text-gray-500">
              ~{approximateMinutes(1, section.questions.length)} min
            </span>
          </li>
        ))}
      </ul>
      <div className="mt-4 text-right font-medium text-gray-700">
        Estimated Total: <span className="text-purple-600">~{totalTime} min</span>
      </div>
    </div>
  );
};

export default InterviewOutline;