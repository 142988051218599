import React, { useState, useMemo, useEffect } from 'react';
import { SortableTable } from './SortableTable';
import { UseCase } from '../../models/UseCase';
import api from '../../api';
import FullPageSpinner from '../FullPageSpinner';
import { useNavigate, useSearchParams } from 'react-router-dom';
import adminApi from '../../admin-api';
import NavigationMenu from '../NavigationMenu';
import { CATEGORIES } from '../../models/Category';

const UseCaseAdmin: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const initialCategory = searchParams.get('category') || CATEGORIES[0].toLowerCase();
  const initialOneOnOne = searchParams.get('one_on_one') !== 'false';

  const [selectedCategory, setSelectedCategory] = useState<string>(initialCategory);
  const [showOneOnOne, setShowOneOnOne] = useState(initialOneOnOne);
  const [useCases, setUseCases] = useState<UseCase[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUseCases = async () => {
      try {
        const fetchedUseCases = await api.getUseCases();
        setUseCases(fetchedUseCases);
      } catch (error) {
        console.error('Failed to fetch use cases:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUseCases();
  }, []);

  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('category', selectedCategory);
    newSearchParams.set('one_on_one', showOneOnOne.toString());
    setSearchParams(newSearchParams);
  }, [selectedCategory, showOneOnOne, setSearchParams]);

  const filteredUseCases = useMemo(() => {
    return useCases.filter(useCase =>
      useCase.category === selectedCategory &&
      (showOneOnOne ? useCase.oneOnOne : !useCase.oneOnOne)
    );
  }, [useCases, showOneOnOne, selectedCategory]);

  const handleDelete = async (id: string) => {
    console.log(`Delete use case with id: ${id}`);
    setIsLoading(true);
    await adminApi.deleteUseCase(id);
    // After successful deletion, refresh the use cases
    const fetchedUseCases = await api.getUseCases();
    setUseCases(fetchedUseCases);
    setIsLoading(false);
  };

  const handleEdit = (id: string) => {
    navigate(`/admin/use-case/${id}`);
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(event.target.value);
  };

  const handleOrderChange = async (newOrder: UseCase[]) => {
    console.log('New order:', newOrder);
    await adminApi.updateUseCaseOrder(
      showOneOnOne,
      selectedCategory,
      newOrder.map(useCase => useCase.id)
    );
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <NavigationMenu />
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-2xl font-bold my-4">Use Cases</h1>

        <div className="mb-4 flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <div>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  checked={showOneOnOne}
                  onChange={(e) => setShowOneOnOne(e.target.checked)}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2 text-gray-700">One-on-One</span>
              </label>
            </div>

            <div className="flex items-center space-x-2">
              <span className="text-gray-700">Category:</span>
              <select
                value={selectedCategory}
                onChange={handleCategoryChange}
                className="form-select mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                {CATEGORIES.map(category => (
                  <option key={category.toLowerCase()} value={category.toLowerCase()}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <button
              onClick={() => navigate('/admin/use-case/new')}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              New Use Case
            </button>
          </div>
        </div>
        <SortableTable
          useCases={filteredUseCases}
          onDelete={handleDelete}
          onEdit={handleEdit}
          orderChange={handleOrderChange}
        />
      </div>
    </>
  );
};

export default UseCaseAdmin;