import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "../api";
import FullPageSpinner from "./FullPageSpinner";
import InterviewManager from "./InterviewManager";
import cloneDeep from 'lodash/cloneDeep';
import { UseCase } from "../models/UseCase";


const InterviewLoader: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [interviewData, setInterviewData] = useState(null);
  const [useCase, setUseCase] = useState<UseCase | undefined>();
  const oneOnOne = (searchParams.get('oneOnOne') || '').toLowerCase() === 'true';
  const useCaseId = searchParams.get('useCaseId');
  const otherParticipant = searchParams.get('recipientName');
  const communicationRequestId = searchParams.get('communicationRequestId') || undefined;


  useEffect(() => {
    const fetchData = async () => {
      if (useCaseId) {
        const useCase = await api.getUseCase(useCaseId);
        setUseCase(useCase)
        const newInterviewData: any = cloneDeep(useCase.script)
        newInterviewData.oneOnOne = useCase.oneOnOne
        newInterviewData.category = useCase.category
        setInterviewData({ ...newInterviewData, otherParticipant });
        console.log({ ...newInterviewData, otherParticipant })
        setLoading(false);
      }
    }
    fetchData();
  }, [])

  const onClose = () => {
    navigate('/');
  }

  if (loading || !interviewData || !useCaseId || !useCase) {
    return <FullPageSpinner />
  }

  return (
    <InterviewManager useCase={useCase} oneOnOne={oneOnOne} interviewData={interviewData} onClose={onClose} communicationRequestId={communicationRequestId} />
  );
}

export default InterviewLoader;