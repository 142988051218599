import { authedFetch } from "./api";
import { UseCaseAddUpdate } from "./models/UseCase";


const updateUseCaseOrder = async (oneOnOne: boolean, category: string, useCaseIds: string[]): Promise<void> => {
  const response = await authedFetch(`/use_case/update_order?one_on_one=${oneOnOne}&category=${category}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ use_case_ids: useCaseIds }),
  });

  if (!response.ok) {
    throw new Error('Failed to update use case order');
  }

  return response.json();
};

const deleteUseCase = async (id: string) => {
  const response = await authedFetch(`/use_case/${id}`, {
    method: 'DELETE',
  });
  if (!response.ok) {
    throw new Error('Failed to delete use case');
  }
  return await response.json();
}

const createUseCase = async (useCase: UseCaseAddUpdate): Promise<string> => {
  const response = await authedFetch('/use_case', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(useCase),
  });
  if (!response.ok) {
    throw new Error('Failed to create use case');
  }
  const data = await response.json();
  return data.id;
}

const updateUseCase = async (id: string, useCase: UseCaseAddUpdate): Promise<void> => {
  const response = await authedFetch(`/use_case/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(useCase),
  });
  if (!response.ok) {
    throw new Error('Failed to update use case');
  }
}

const executeSelect = async (sql: string): Promise<any[]> => {
  const response = await authedFetch(`/admin/execute-select?sql=${encodeURIComponent(sql)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to executeSelect');
  }
  return await response.json();
}

const adminApi = {
  createUseCase,
  updateUseCase,
  updateUseCaseOrder,
  deleteUseCase,
  executeSelect,
}

export default adminApi