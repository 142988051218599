import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import FullPageSpinner from '../../components/FullPageSpinner';
import adminApi from '../../admin-api';
import NavigationMenu from '../NavigationMenu';
import { v4 as uuidv4 } from 'uuid';
import api, { Outline } from '../../api';
import { FaSpinner } from 'react-icons/fa';
import { startCase } from 'lodash';
import CommunicationOutline from '../CommunicationOutline';

interface Question {
  question: string;
  answer?: string;
  answerAudio?: string;
}

interface Section {
  title: string;
  summary: string;
  questions: Question[];
  summaryQuestion?: Question;
  preamble?: string;
  preambleAudio?: string;
}

interface Interview {
  id: string;
  title: string;
  category: string;
  oneOnOne: boolean;
  sections: Section[];
  recipient: string;
  inStyleOf: string;
  targetWordLength: number;
  otherParticipant?: string;
}

interface Communication {
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  status: string;
  text: string;
  originalText: string;
  audioUrl: string;
  interview: Interview;
  outline?: Outline;
  outputs?: {
    email?: string;
    memoToFile?: string;
  }
}

const countWords = (text?: string): number => {
  if (!text) {
    return 0
  }
  return text.trim().split(/\s+/).length;
};

const CommunicationDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const isInterview = new URLSearchParams(location.search).get('interviewId') === 'true';
  const [communication, setCommunication] = useState<Communication | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [originalTextWordCount, setOriginalTextWordCount] = useState(0);
  const [totalAnswersWordCount, setTotalAnswersWordCount] = useState(0);
  const [testServerUrl, setTestServerUrl] = useState('');
  const [testServerToken, setTestServerToken] = useState('');
  const [loadingSections, setLoadingSections] = useState<{ [key: number]: boolean }>({});
  const [loadingResponsiveQuestions, setLoadingResponsiveQuestions] = useState<{ [key: number]: boolean }>({});
  const [responsiveInterview, setResponsiveInterview] = useState<Interview | null>(null);
  const [activeTab, setActiveTab] = useState<string>('script');

  useEffect(() => {
    const fetchData = async () => {
      console.log(id);
      if (!id) return;

      try {
        let result;
        if (isInterview) {
          result = await fetchInterviewData(id);
        } else {
          result = await fetchCommunicationData(id);
        }
        setCommunication(result);
        console.log(result);
        setOriginalTextWordCount(countWords(result.originalText || ''));

        let answersWordCount = 0;
        result.interview.sections.forEach((section: Section) => {
          section.questions.forEach((qa: Question) => {
            answersWordCount += countWords(qa.answer);
          });
          if (section.summaryQuestion) {
            answersWordCount += countWords(section.summaryQuestion.answer);
          }
        });
        setTotalAnswersWordCount(answersWordCount);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id, isInterview]);

  const fetchCommunicationData = async (communicationId: string): Promise<Communication> => {
    const sql = `
      SELECT 
        u.email,
        u.first_name,
        u.last_name,
        c.title,
        c.status,
        c.text,
        c.original_text,
        c.audio_url,
        c.interview,
        c.outline,
        c.outputs
      FROM 
        communication c 
        LEFT OUTER JOIN user u ON u.id = c.user_id 
      WHERE 
        c.id = '${communicationId}';
    `;
    const result = await adminApi.executeSelect(sql);
    if (result.length > 0) {
      const comm = result[0];
      return {
        email: comm.email,
        firstName: comm.firstName,
        lastName: comm.lastName,
        title: comm.title,
        status: comm.status,
        originalText: comm.originalText,
        text: comm.text,
        audioUrl: comm.audioUrl,
        interview: JSON.parse(comm.interview),
        outline: comm.outline ? JSON.parse(comm.outline) : undefined,
        outputs: comm.outputs ? JSON.parse(comm.outputs) : undefined,
      };
    }
    throw new Error('Communication not found');
  };

  const fetchInterviewData = async (interviewId: string): Promise<Communication> => {
    // Fetch interview details
    const interviewSql = `
      SELECT 
        i.id,
        i.user_id,
        i.use_case_id,
        i.recipient_name,
        u.email,
        u.first_name,
        u.last_name,
        uc.category,
        uc.one_on_one,
        uc.script
      FROM 
        interview i
        LEFT OUTER JOIN user u ON u.id = i.user_id
        LEFT OUTER JOIN use_case uc ON uc.id = i.use_case_id
      WHERE 
        i.id = '${interviewId}';
    `;
    const interviewResult = await adminApi.executeSelect(interviewSql);
    if (interviewResult.length === 0) {
      throw new Error('Interview not found');
    }
    const interviewData = interviewResult[0];

    // Fetch interview answers
    const answersSql = `
      SELECT * FROM interview_answer
      WHERE interview_id = '${interviewId}'
      ORDER BY section_number, question_number, is_summary_answer;
    `;
    const answersResult = await adminApi.executeSelect(answersSql);

    // Fetch section summaries
    const summariesSql = `
      SELECT * FROM interview_section_summary
      WHERE interview_id = '${interviewId}'
      ORDER BY section_number;
    `;
    const summariesResult = await adminApi.executeSelect(summariesSql);

    // Parse the use case script
    const useCase = JSON.parse(interviewData.script);

    // Construct the interview object
    const interview: Interview = {
      id: interviewData.id,
      title: useCase.title,
      category: interviewData.category,
      oneOnOne: interviewData.oneOnOne,
      sections: useCase.sections.map((section: any, sectionIndex: number) => {
        const sectionAnswers = answersResult.filter(a => a.sectionNumber === sectionIndex + 1);
        const sectionSummary = summariesResult.find(s => s.sectionNumber === sectionIndex + 1);

        return {
          title: section.title,
          summary: sectionSummary ? sectionSummary.text : '',
          questions: section.questions.map((q: any, questionIndex: number) => {
            const answer = sectionAnswers.find(a => a.questionNumber === questionIndex + 1 && !a.isSummaryAnswer);
            return {
              ...q,
              answer: answer ? answer.text : '',
              answerAudio: answer ? answer.audioUrl : '',
            };
          }),
          summaryQuestion: section.summaryQuestion ? {
            ...section.summaryQuestion,
            answer: sectionAnswers.find(a => a.isSummaryAnswer)?.text || '',
            answerAudio: sectionAnswers.find(a => a.isSummaryAnswer)?.audioUrl || '',
          } : undefined,
          preamble: section.preamble,
          preambleAudio: section.preambleAudio,
        };
      }),
      recipient: interviewData.recipientName || useCase.recipient,
      inStyleOf: useCase.inStyleOf,
      targetWordLength: useCase.targetWordLength,
    };

    return {
      email: interviewData.email,
      firstName: interviewData.firstName,
      lastName: interviewData.lastName,
      title: useCase.title,
      status: 'In Progress', // Assuming partial interviews are always "In Progress"
      originalText: '', // No original text for partial interviews
      text: '',
      audioUrl: '', // No audio URL for partial interviews
      interview,
    };
  };

  const handleGenerateCommunication = async (isTestServer: boolean = false) => {
    if (!communication) return;

    const newInterviewId = uuidv4();
    const interviewData = {
      ...communication.interview,
      id: newInterviewId
    };

    setIsLoading(true);
    if (isTestServer) {
      api.setBaseUrlAndToken(testServerUrl, testServerToken);
    }
    api.generateCommunication(interviewData);
    let intervalId: any = null;
    let polls = 0;
    const getCommunications = async () => {
      if (!interviewData.id) {
        throw new Error('should not be polling without an interviewId')
      }
      if (isTestServer) {
        api.setBaseUrlAndToken(testServerUrl, testServerToken);
      }
      const comms = await api.getCommunication(newInterviewId, true);
      polls += 1;
      console.log('Polling communication status', polls)
      if (comms.status === 'FINISHED') {
        clearInterval(intervalId);
        if (isTestServer) {
          // Use the test server URL
          window.location.href = `https://jesse.ngrok.dev/admin/communication/${comms.id}`;
        } else {
          navigate(`/admin/communication/${comms.id}`);
        }
      } else if (polls > 600) {
        // give up after 10 minutes
        clearInterval(intervalId);
        console.error('Polling communications timed out');
      }
    }
    intervalId = setInterval(getCommunications, 1000);
  };

  const handleRegenerateSummary = async (sectionIndex: number) => {
    if (!communication) return;

    setLoadingSections(prev => ({ ...prev, [sectionIndex]: true }));
    try {
      const result = await api.saveAndSummarize(sectionIndex + 1, communication.interview, false);
      console.log('Regenerate summary result', result);
      if (result && result.text) {
        setCommunication(prevCommunication => {
          if (!prevCommunication) return null;
          const updatedCommunication = { ...prevCommunication };
          updatedCommunication.interview.sections[sectionIndex].summary = result.text;
          return updatedCommunication;
        });
      }
    } finally {
      setLoadingSections(prev => ({ ...prev, [sectionIndex]: false }));
    }
  };

  const handleResponsiveQuestions = async (sectionIndex: number) => {
    if (!communication) return;

    setLoadingResponsiveQuestions(prev => ({ ...prev, [sectionIndex]: true }));
    try {
      const result = await api.getResponsiveQuestions((communication.interview as any).id, sectionIndex + 1, communication.interview);
      setResponsiveInterview(result);
    } finally {
      setLoadingResponsiveQuestions(prev => ({ ...prev, [sectionIndex]: false }));
    }
  };

  const getResponsiveSection = (sectionIndex: number): Section | undefined => {
    const originalSection = communication?.interview.sections[sectionIndex];

    if (originalSection) {
      // find the section in responsiveInterview that has the same title as originalSection
      const responsiveSection = responsiveInterview?.sections?.find(s => s.title === originalSection.title);
      return responsiveSection;
    }
    return undefined;
  };

  const getResponsiveQuestion = (sectionIndex: number, questionIndex: number): string | undefined => {
    const responsiveQuestion = getResponsiveSection(sectionIndex)?.questions?.[questionIndex]?.question;
    const originalQuestion = communication?.interview.sections[sectionIndex]?.questions[questionIndex]?.question;

    if (responsiveQuestion && responsiveQuestion !== originalQuestion) {
      return responsiveQuestion;
    }
    return undefined;
  };

  const getResponsivePreamble = (sectionIndex: number): string | undefined => {
    const responsivePreamble = getResponsiveSection(sectionIndex)?.preamble;
    const originalPreamble = communication?.interview.sections[sectionIndex]?.preamble;

    if (responsivePreamble && responsivePreamble !== originalPreamble) {
      return responsivePreamble;
    }
    return undefined;
  };

  const getResponsiveSummaryQuestion = (sectionIndex: number): string | undefined => {
    const responsiveSummaryQuestion = getResponsiveSection(sectionIndex)?.summaryQuestion?.question;
    const originalSummaryQuestion = communication?.interview.sections[sectionIndex]?.summaryQuestion?.question;

    if (responsiveSummaryQuestion && responsiveSummaryQuestion !== originalSummaryQuestion) {
      return responsiveSummaryQuestion;
    }
    return undefined;
  };

  const renderResponsiveQuestion = (sectionIndex: number, questionIndex: number, isSummary = false) => {
    const responsiveQuestion = isSummary
      ? getResponsiveSummaryQuestion(sectionIndex)
      : getResponsiveQuestion(sectionIndex, questionIndex);

    if (!responsiveQuestion) return null;

    return (
      <div className="mt-4 pl-4 border-l-4 border-purple-500">
        <h5 className="text-md font-semibold mb-2 text-purple-700">
          Responsive {isSummary ? 'Summary ' : ''}Question:
        </h5>
        <div className="mb-2 bg-purple-50 p-2 rounded">
          <p className="font-medium text-purple-800">{responsiveQuestion}</p>
        </div>
      </div>
    );
  };

  const renderQuestion = (sectionIndex: number, questionIndex: number, isExtra = false) => {
    const originalQuestion = communication?.interview.sections[sectionIndex]?.questions[questionIndex];
    const responsiveQuestion = getResponsiveQuestion(sectionIndex, questionIndex);

    return (
      <div key={questionIndex} className="mb-4 bg-white p-4 rounded-md">
        {!isExtra && (
          <>
            <p className="font-semibold">{originalQuestion?.question}</p>
            <p>{originalQuestion?.answer || "Not answered yet"}</p>
            <p className="text-sm text-gray-500 mt-1">Word count: {countWords(originalQuestion?.answer)}</p>
            {originalQuestion?.answerAudio && (
              <audio controls src={originalQuestion.answerAudio} className="mt-2 w-full">
                Your browser does not support the audio element.
              </audio>
            )}
          </>
        )}
        {responsiveQuestion && responsiveQuestion !== originalQuestion?.question && (
          <div className="mt-4 pl-4 border-l-4 border-purple-500">
            <h5 className="text-md font-semibold mb-2 text-purple-700">
              {isExtra ? `Extra Responsive Question ${questionIndex + 1}:` : 'Responsive Question:'}
            </h5>
            <div className="mb-2 bg-purple-50 p-2 rounded">
              <p className="font-medium text-purple-800">{responsiveQuestion}</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderTabs = () => {
    if (!communication) return null;

    const tabs = ['script'];
    if (communication.outline) {
      tabs.push('outline');
    }
    if (communication.outputs?.email) {
      tabs.push('email');
    }
    if (communication.outputs?.memoToFile) {
      tabs.push('memoToFile');
    }

    return (
      <div className="flex border-b mb-4">
        {tabs.map(tab => (
          <button
            className={`py-2 px-4 ${activeTab === tab ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
            onClick={() => setActiveTab(tab)}
            key={tab}
          >
            {startCase(tab)}
          </button>
        ))}
      </div>
    );
  };

  const renderTabContent = () => {
    if (!communication) return null;

    switch (activeTab) {
      case 'script':
        return (
          <>
            <h2 className="text-2xl font-semibold mb-4">Script</h2>
            <p className="mb-2"><strong>Word Count:</strong> {originalTextWordCount}</p>
            <p className="whitespace-pre-wrap bg-gray-100 p-4 rounded">{communication.originalText}</p>
            {communication.text && communication.text !== communication.originalText && (
              <div className="mt-4">
                <h3 className="text-xl font-semibold mb-2">Edited Script</h3>
                <p className="whitespace-pre-wrap bg-gray-100 p-4 rounded">{communication.text}</p>
              </div>
            )}
          </>
        );
      case 'outline':
        return (
          <CommunicationOutline
            communicationId={communication.interview.id}
            outline={communication.outline}
          />
        );
      case 'email':
        return (
          <div className="whitespace-pre-wrap bg-gray-100 p-4 rounded">
            {communication.outputs?.email}
          </div>
        );
      case 'memoToFile':
        return (
          <div className="whitespace-pre-wrap bg-gray-100 p-4 rounded">
            {communication.outputs?.memoToFile}
          </div>
        );
      default:
        return null;
    }
  };

  const renderInterviewContent = () => {
    if (!communication) return null;

    return (
      <>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div>
            <h2 className="text-xl font-semibold mb-2">User Information</h2>
            <p><strong>Name:</strong> {communication.firstName} {communication.lastName}</p>
            <p><strong>Email:</strong> {communication.email}</p>
            <p><strong>Status:</strong> {communication.status}</p>
          </div>
          <div>
            <h2 className="text-xl font-semibold mb-2">Interview Details</h2>
            <p><strong>Category:</strong> {communication.interview.category}</p>
            <p><strong>One-on-One:</strong> {communication.interview.oneOnOne ? 'Yes' : 'No'}</p>
            <p><strong>Recipient:</strong> {communication.interview.recipient}</p>
            {communication.interview.oneOnOne && <p><strong>Recipient Name:</strong> {communication.interview.otherParticipant}</p>}
            <p><strong>In Style Of:</strong> {communication.interview.inStyleOf}</p>
            <p><strong>Target Word Length:</strong> {communication.interview.targetWordLength}</p>
          </div>
        </div>

        <div className="mb-8">
          {renderTabs()}
          {renderTabContent()}
        </div>

        {communication.audioUrl && (
          <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Audio</h2>
            <audio controls src={communication.audioUrl} className="w-full">
              Your browser does not support the audio element.
            </audio>
          </div>
        )}

        <div>
          <h2 className="text-2xl font-semibold mb-4">Interview Sections</h2>
          <p className="mb-4"><strong>Total Word Count of All Answers:</strong> {totalAnswersWordCount}</p>
          {communication.interview.sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="mb-6 p-6 bg-gray-50 rounded-lg">
              <h3 className="text-xl font-semibold mb-4">{section.title}</h3>

              {section.preamble && (
                <div className="mb-6">
                  <h4 className="text-lg font-semibold mb-2">Preamble</h4>
                  <p className="whitespace-pre-wrap bg-white p-4 rounded-md mb-4">{section.preamble}</p>
                  {(() => {
                    const responsivePreamble = getResponsivePreamble(sectionIndex);
                    if (responsivePreamble) {
                      return (
                        <div className="mt-4 pl-4 border-l-4 border-purple-500">
                          <h5 className="text-md font-semibold mb-2 text-purple-700">Responsive Preamble:</h5>
                          <div className="mb-2 bg-purple-50 p-2 rounded">
                            <p className="font-medium text-purple-800">{responsivePreamble}</p>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })()}
                </div>
              )}

              <div className="mb-6">
                <h4 className="text-lg font-semibold mb-2">Questions and Answers</h4>
                {section.questions.map((_, questionIndex) => renderQuestion(sectionIndex, questionIndex))}
                {getResponsiveSection(sectionIndex)?.questions?.slice(section.questions.length).map((_, index) =>
                  renderQuestion(sectionIndex, section.questions.length + index, true)
                )}
              </div>

              {(section.summary || section.summaryQuestion) && (
                <div className="mt-6 bg-white p-4 rounded-md">
                  <div className="flex justify-between items-center mb-2">
                    <h4 className="text-lg font-semibold">Section Summary</h4>
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleRegenerateSummary(sectionIndex)}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-sm flex items-center"
                        disabled={loadingSections[sectionIndex]}
                      >
                        {loadingSections[sectionIndex] ? (
                          <FaSpinner className="animate-spin mr-2" />
                        ) : null}
                        {loadingSections[sectionIndex] ? 'Regenerating...' : 'Regenerate Summary'}
                      </button>
                      <button
                        onClick={() => handleResponsiveQuestions(sectionIndex)}
                        className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-1 px-2 rounded text-sm flex items-center"
                        disabled={loadingResponsiveQuestions[sectionIndex]}
                      >
                        {loadingResponsiveQuestions[sectionIndex] ? (
                          <FaSpinner className="animate-spin mr-2" />
                        ) : null}
                        {loadingResponsiveQuestions[sectionIndex] ? 'Generating...' : 'Responsive Questions'}
                      </button>
                    </div>
                  </div>
                  {section.summary && (
                    <p key={section.summary} className="mb-4">{section.summary}</p>
                  )}
                  {section.summaryQuestion && (
                    <div className="mt-4 border-t pt-4">
                      <p className="font-semibold">{section.summaryQuestion.question}</p>
                      <p>{section.summaryQuestion.answer || "Not answered yet"}</p>
                      <p className="text-sm text-gray-500 mt-1">Word count: {countWords(section.summaryQuestion.answer)}</p>
                      {section.summaryQuestion.answerAudio && (
                        <audio controls src={section.summaryQuestion.answerAudio} className="mt-2 w-full">
                          Your browser does not support the audio element.
                        </audio>
                      )}
                      {renderResponsiveQuestion(sectionIndex, 0, true)}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </>
    );
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }

  if (!communication) {
    return <div className="text-center mt-8">Communication not found.</div>;
  }

  return (
    <>
      <NavigationMenu />
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-3xl font-bold mb-6">{communication.title}</h1>

        <div className="flex flex-col space-y-4 mb-6">
          <div className="flex space-x-4">
            <button
              onClick={() => handleGenerateCommunication()}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Regenerate Communication
            </button>
          </div>
        </div>

        {renderInterviewContent()}

        <div className="flex flex-col space-y-4 mt-8">
          <div className="flex items-center space-x-2">
            <label htmlFor="testServerUrl" className="font-semibold">Test Server URL:</label>
            <input
              type="text"
              id="testServerUrl"
              value={testServerUrl}
              onChange={(e) => setTestServerUrl(e.target.value)}
              className="border rounded px-2 py-1 flex-grow"
            />
          </div>
          <div className="flex items-center space-x-2">
            <label htmlFor="testServerToken" className="font-semibold">Test Server Token:</label>
            <input
              type="text"
              id="testServerToken"
              value={testServerToken}
              onChange={(e) => setTestServerToken(e.target.value)}
              className="border rounded px-2 py-1 flex-grow"
            />
          </div>
          <button
            onClick={() => handleGenerateCommunication(true)}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded self-start"
          >
            Regenerate on Test Server
          </button>
        </div>
      </div>
    </>
  );
};

export default CommunicationDetail;