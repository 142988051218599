import { useState, useEffect } from 'react';

const useViewportHeight = () => {
  const [vh, setVh] = useState(0);

  useEffect(() => {
    const updateVh = () => {
      const newVh = window.innerHeight * 0.01;
      setVh(newVh);
      document.documentElement.style.setProperty('--vh', `${newVh}px`);
    };

    updateVh();
    window.addEventListener('resize', updateVh);

    return () => window.removeEventListener('resize', updateVh);
  }, []);

  return vh;
};

export default useViewportHeight;