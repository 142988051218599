
/* eslint-disable */
// @ts-nocheck
"use strict";


var schema22 = {"type":"object","additionalProperties":false,"properties":{"title":{"type":"string"},"recipient":{"type":"string"},"inStyleOf":{"type":"string"},"targetWordLength":{"type":"integer"},"sections":{"type":"array","items":{"type":"object","additionalProperties":false,"properties":{"title":{"type":"string"},"preamble":{"type":["string","null"]},"preambleAudio":{"type":["string","null"]},"skipSummary":{"type":["boolean","null"]},"questions":{"type":"array","items":{"type":"object","additionalProperties":false,"properties":{"question":{"type":"string"},"questionAudio":{"type":["string","null"]}},"required":["question"]}},"summaryQuestion":{"type":["object","null"],"additionalProperties":false,"properties":{"question":{"type":"string"},"questionAudio":{"type":["string","null"]}},"required":["question"]}},"required":["title","questions"]}},"outputs":{"type":"array","items":{"type":"string","enum":["email","memoToFile"]}}},"required":["title","recipient","inStyleOf","targetWordLength","sections"]};

function validate20(data, valCxt){
"use strict"; ;
if(valCxt){
var instancePath = valCxt.instancePath;
var parentData = valCxt.parentData;
var parentDataProperty = valCxt.parentDataProperty;
var rootData = valCxt.rootData;
}
else {
var instancePath = "";
var parentData = undefined;
var parentDataProperty = undefined;
var rootData = data;
}
var vErrors = null;
var errors = 0;
if(data && typeof data == "object" && !Array.isArray(data)){
if(data.title === undefined){
var err0 = {instancePath:instancePath,schemaPath:"#/required",keyword:"required",params:{missingProperty: "title"},message:"must have required property '"+"title"+"'"};
if(vErrors === null){
vErrors = [err0];
}
else {
vErrors.push(err0);
}
errors++;
}
if(data.recipient === undefined){
var err1 = {instancePath:instancePath,schemaPath:"#/required",keyword:"required",params:{missingProperty: "recipient"},message:"must have required property '"+"recipient"+"'"};
if(vErrors === null){
vErrors = [err1];
}
else {
vErrors.push(err1);
}
errors++;
}
if(data.inStyleOf === undefined){
var err2 = {instancePath:instancePath,schemaPath:"#/required",keyword:"required",params:{missingProperty: "inStyleOf"},message:"must have required property '"+"inStyleOf"+"'"};
if(vErrors === null){
vErrors = [err2];
}
else {
vErrors.push(err2);
}
errors++;
}
if(data.targetWordLength === undefined){
var err3 = {instancePath:instancePath,schemaPath:"#/required",keyword:"required",params:{missingProperty: "targetWordLength"},message:"must have required property '"+"targetWordLength"+"'"};
if(vErrors === null){
vErrors = [err3];
}
else {
vErrors.push(err3);
}
errors++;
}
if(data.sections === undefined){
var err4 = {instancePath:instancePath,schemaPath:"#/required",keyword:"required",params:{missingProperty: "sections"},message:"must have required property '"+"sections"+"'"};
if(vErrors === null){
vErrors = [err4];
}
else {
vErrors.push(err4);
}
errors++;
}
for(var key0 in data){
if(!((((((key0 === "title") || (key0 === "recipient")) || (key0 === "inStyleOf")) || (key0 === "targetWordLength")) || (key0 === "sections")) || (key0 === "outputs"))){
var err5 = {instancePath:instancePath,schemaPath:"#/additionalProperties",keyword:"additionalProperties",params:{additionalProperty: key0},message:"must NOT have additional properties"};
if(vErrors === null){
vErrors = [err5];
}
else {
vErrors.push(err5);
}
errors++;
}
}
if(data.title !== undefined){
if(typeof data.title !== "string"){
var err6 = {instancePath:instancePath+"/title",schemaPath:"#/properties/title/type",keyword:"type",params:{type: "string"},message:"must be string"};
if(vErrors === null){
vErrors = [err6];
}
else {
vErrors.push(err6);
}
errors++;
}
}
if(data.recipient !== undefined){
if(typeof data.recipient !== "string"){
var err7 = {instancePath:instancePath+"/recipient",schemaPath:"#/properties/recipient/type",keyword:"type",params:{type: "string"},message:"must be string"};
if(vErrors === null){
vErrors = [err7];
}
else {
vErrors.push(err7);
}
errors++;
}
}
if(data.inStyleOf !== undefined){
if(typeof data.inStyleOf !== "string"){
var err8 = {instancePath:instancePath+"/inStyleOf",schemaPath:"#/properties/inStyleOf/type",keyword:"type",params:{type: "string"},message:"must be string"};
if(vErrors === null){
vErrors = [err8];
}
else {
vErrors.push(err8);
}
errors++;
}
}
if(data.targetWordLength !== undefined){
var data3 = data.targetWordLength;
if(!(((typeof data3 == "number") && (!(data3 % 1) && !isNaN(data3))) && (isFinite(data3)))){
var err9 = {instancePath:instancePath+"/targetWordLength",schemaPath:"#/properties/targetWordLength/type",keyword:"type",params:{type: "integer"},message:"must be integer"};
if(vErrors === null){
vErrors = [err9];
}
else {
vErrors.push(err9);
}
errors++;
}
}
if(data.sections !== undefined){
var data4 = data.sections;
if(Array.isArray(data4)){
var len0 = data4.length;
for(var i0=0; i0<len0; i0++){
var data5 = data4[i0];
if(data5 && typeof data5 == "object" && !Array.isArray(data5)){
if(data5.title === undefined){
var err10 = {instancePath:instancePath+"/sections/" + i0,schemaPath:"#/properties/sections/items/required",keyword:"required",params:{missingProperty: "title"},message:"must have required property '"+"title"+"'"};
if(vErrors === null){
vErrors = [err10];
}
else {
vErrors.push(err10);
}
errors++;
}
if(data5.questions === undefined){
var err11 = {instancePath:instancePath+"/sections/" + i0,schemaPath:"#/properties/sections/items/required",keyword:"required",params:{missingProperty: "questions"},message:"must have required property '"+"questions"+"'"};
if(vErrors === null){
vErrors = [err11];
}
else {
vErrors.push(err11);
}
errors++;
}
for(var key1 in data5){
if(!((((((key1 === "title") || (key1 === "preamble")) || (key1 === "preambleAudio")) || (key1 === "skipSummary")) || (key1 === "questions")) || (key1 === "summaryQuestion"))){
var err12 = {instancePath:instancePath+"/sections/" + i0,schemaPath:"#/properties/sections/items/additionalProperties",keyword:"additionalProperties",params:{additionalProperty: key1},message:"must NOT have additional properties"};
if(vErrors === null){
vErrors = [err12];
}
else {
vErrors.push(err12);
}
errors++;
}
}
if(data5.title !== undefined){
if(typeof data5.title !== "string"){
var err13 = {instancePath:instancePath+"/sections/" + i0+"/title",schemaPath:"#/properties/sections/items/properties/title/type",keyword:"type",params:{type: "string"},message:"must be string"};
if(vErrors === null){
vErrors = [err13];
}
else {
vErrors.push(err13);
}
errors++;
}
}
if(data5.preamble !== undefined){
var data7 = data5.preamble;
if((typeof data7 !== "string") && (data7 !== null)){
var err14 = {instancePath:instancePath+"/sections/" + i0+"/preamble",schemaPath:"#/properties/sections/items/properties/preamble/type",keyword:"type",params:{type: schema22.properties.sections.items.properties.preamble.type},message:"must be string,null"};
if(vErrors === null){
vErrors = [err14];
}
else {
vErrors.push(err14);
}
errors++;
}
}
if(data5.preambleAudio !== undefined){
var data8 = data5.preambleAudio;
if((typeof data8 !== "string") && (data8 !== null)){
var err15 = {instancePath:instancePath+"/sections/" + i0+"/preambleAudio",schemaPath:"#/properties/sections/items/properties/preambleAudio/type",keyword:"type",params:{type: schema22.properties.sections.items.properties.preambleAudio.type},message:"must be string,null"};
if(vErrors === null){
vErrors = [err15];
}
else {
vErrors.push(err15);
}
errors++;
}
}
if(data5.skipSummary !== undefined){
var data9 = data5.skipSummary;
if((typeof data9 !== "boolean") && (data9 !== null)){
var err16 = {instancePath:instancePath+"/sections/" + i0+"/skipSummary",schemaPath:"#/properties/sections/items/properties/skipSummary/type",keyword:"type",params:{type: schema22.properties.sections.items.properties.skipSummary.type},message:"must be boolean,null"};
if(vErrors === null){
vErrors = [err16];
}
else {
vErrors.push(err16);
}
errors++;
}
}
if(data5.questions !== undefined){
var data10 = data5.questions;
if(Array.isArray(data10)){
var len1 = data10.length;
for(var i1=0; i1<len1; i1++){
var data11 = data10[i1];
if(data11 && typeof data11 == "object" && !Array.isArray(data11)){
if(data11.question === undefined){
var err17 = {instancePath:instancePath+"/sections/" + i0+"/questions/" + i1,schemaPath:"#/properties/sections/items/properties/questions/items/required",keyword:"required",params:{missingProperty: "question"},message:"must have required property '"+"question"+"'"};
if(vErrors === null){
vErrors = [err17];
}
else {
vErrors.push(err17);
}
errors++;
}
for(var key2 in data11){
if(!((key2 === "question") || (key2 === "questionAudio"))){
var err18 = {instancePath:instancePath+"/sections/" + i0+"/questions/" + i1,schemaPath:"#/properties/sections/items/properties/questions/items/additionalProperties",keyword:"additionalProperties",params:{additionalProperty: key2},message:"must NOT have additional properties"};
if(vErrors === null){
vErrors = [err18];
}
else {
vErrors.push(err18);
}
errors++;
}
}
if(data11.question !== undefined){
if(typeof data11.question !== "string"){
var err19 = {instancePath:instancePath+"/sections/" + i0+"/questions/" + i1+"/question",schemaPath:"#/properties/sections/items/properties/questions/items/properties/question/type",keyword:"type",params:{type: "string"},message:"must be string"};
if(vErrors === null){
vErrors = [err19];
}
else {
vErrors.push(err19);
}
errors++;
}
}
if(data11.questionAudio !== undefined){
var data13 = data11.questionAudio;
if((typeof data13 !== "string") && (data13 !== null)){
var err20 = {instancePath:instancePath+"/sections/" + i0+"/questions/" + i1+"/questionAudio",schemaPath:"#/properties/sections/items/properties/questions/items/properties/questionAudio/type",keyword:"type",params:{type: schema22.properties.sections.items.properties.questions.items.properties.questionAudio.type},message:"must be string,null"};
if(vErrors === null){
vErrors = [err20];
}
else {
vErrors.push(err20);
}
errors++;
}
}
}
else {
var err21 = {instancePath:instancePath+"/sections/" + i0+"/questions/" + i1,schemaPath:"#/properties/sections/items/properties/questions/items/type",keyword:"type",params:{type: "object"},message:"must be object"};
if(vErrors === null){
vErrors = [err21];
}
else {
vErrors.push(err21);
}
errors++;
}
}
}
else {
var err22 = {instancePath:instancePath+"/sections/" + i0+"/questions",schemaPath:"#/properties/sections/items/properties/questions/type",keyword:"type",params:{type: "array"},message:"must be array"};
if(vErrors === null){
vErrors = [err22];
}
else {
vErrors.push(err22);
}
errors++;
}
}
if(data5.summaryQuestion !== undefined){
var data14 = data5.summaryQuestion;
if((!(data14 && typeof data14 == "object" && !Array.isArray(data14))) && (data14 !== null)){
var err23 = {instancePath:instancePath+"/sections/" + i0+"/summaryQuestion",schemaPath:"#/properties/sections/items/properties/summaryQuestion/type",keyword:"type",params:{type: schema22.properties.sections.items.properties.summaryQuestion.type},message:"must be object,null"};
if(vErrors === null){
vErrors = [err23];
}
else {
vErrors.push(err23);
}
errors++;
}
if(data14 && typeof data14 == "object" && !Array.isArray(data14)){
if(data14.question === undefined){
var err24 = {instancePath:instancePath+"/sections/" + i0+"/summaryQuestion",schemaPath:"#/properties/sections/items/properties/summaryQuestion/required",keyword:"required",params:{missingProperty: "question"},message:"must have required property '"+"question"+"'"};
if(vErrors === null){
vErrors = [err24];
}
else {
vErrors.push(err24);
}
errors++;
}
for(var key3 in data14){
if(!((key3 === "question") || (key3 === "questionAudio"))){
var err25 = {instancePath:instancePath+"/sections/" + i0+"/summaryQuestion",schemaPath:"#/properties/sections/items/properties/summaryQuestion/additionalProperties",keyword:"additionalProperties",params:{additionalProperty: key3},message:"must NOT have additional properties"};
if(vErrors === null){
vErrors = [err25];
}
else {
vErrors.push(err25);
}
errors++;
}
}
if(data14.question !== undefined){
if(typeof data14.question !== "string"){
var err26 = {instancePath:instancePath+"/sections/" + i0+"/summaryQuestion/question",schemaPath:"#/properties/sections/items/properties/summaryQuestion/properties/question/type",keyword:"type",params:{type: "string"},message:"must be string"};
if(vErrors === null){
vErrors = [err26];
}
else {
vErrors.push(err26);
}
errors++;
}
}
if(data14.questionAudio !== undefined){
var data16 = data14.questionAudio;
if((typeof data16 !== "string") && (data16 !== null)){
var err27 = {instancePath:instancePath+"/sections/" + i0+"/summaryQuestion/questionAudio",schemaPath:"#/properties/sections/items/properties/summaryQuestion/properties/questionAudio/type",keyword:"type",params:{type: schema22.properties.sections.items.properties.summaryQuestion.properties.questionAudio.type},message:"must be string,null"};
if(vErrors === null){
vErrors = [err27];
}
else {
vErrors.push(err27);
}
errors++;
}
}
}
}
}
else {
var err28 = {instancePath:instancePath+"/sections/" + i0,schemaPath:"#/properties/sections/items/type",keyword:"type",params:{type: "object"},message:"must be object"};
if(vErrors === null){
vErrors = [err28];
}
else {
vErrors.push(err28);
}
errors++;
}
}
}
else {
var err29 = {instancePath:instancePath+"/sections",schemaPath:"#/properties/sections/type",keyword:"type",params:{type: "array"},message:"must be array"};
if(vErrors === null){
vErrors = [err29];
}
else {
vErrors.push(err29);
}
errors++;
}
}
if(data.outputs !== undefined){
var data17 = data.outputs;
if(Array.isArray(data17)){
var len2 = data17.length;
for(var i2=0; i2<len2; i2++){
var data18 = data17[i2];
if(typeof data18 !== "string"){
var err30 = {instancePath:instancePath+"/outputs/" + i2,schemaPath:"#/properties/outputs/items/type",keyword:"type",params:{type: "string"},message:"must be string"};
if(vErrors === null){
vErrors = [err30];
}
else {
vErrors.push(err30);
}
errors++;
}
if(!((data18 === "email") || (data18 === "memoToFile"))){
var err31 = {instancePath:instancePath+"/outputs/" + i2,schemaPath:"#/properties/outputs/items/enum",keyword:"enum",params:{allowedValues: schema22.properties.outputs.items.enum},message:"must be equal to one of the allowed values"};
if(vErrors === null){
vErrors = [err31];
}
else {
vErrors.push(err31);
}
errors++;
}
}
}
else {
var err32 = {instancePath:instancePath+"/outputs",schemaPath:"#/properties/outputs/type",keyword:"type",params:{type: "array"},message:"must be array"};
if(vErrors === null){
vErrors = [err32];
}
else {
vErrors.push(err32);
}
errors++;
}
}
}
else {
var err33 = {instancePath:instancePath,schemaPath:"#/type",keyword:"type",params:{type: "object"},message:"must be object"};
if(vErrors === null){
vErrors = [err33];
}
else {
vErrors.push(err33);
}
errors++;
}
validate20.errors = vErrors;
return errors === 0;
}

export default validate20;