import { CommunicationRequestAdd, CommunicationRequestCounts, CommunicationRequestsAll } from "./models/CommunicationRequest";
import { UseCase } from "./models/UseCase";
import { UserUpdate } from "./models/User";
import { CommunicationShareCounts } from "./models/CommunicationShare";

const API_URL = process.env.REACT_APP_API_URL;
let TEMPORARY_API_URL = API_URL;
let TEMPORARY_API_TOKEN: string | null = null;

export const authedFetch = async (urlPath: string, options: any = {}) => {
  const url = `${TEMPORARY_API_URL}${urlPath}`;
  options.headers = options.headers || {};
  const token = localStorage.getItem('token');
  options.headers['Authorization'] = `Bearer ${TEMPORARY_API_TOKEN || token}`;
  options.headers['ngrok-skip-browser-warning'] = 'true';
  resetBaseUrlAndToken() // only use temporary url for a single request
  return await fetch(url, options);
}

export const unauthedFetch = async (urlPath: string, options: any = {}) => {
  const url = `${TEMPORARY_API_URL}${urlPath}`;
  options.headers = options.headers || {};
  options.headers['ngrok-skip-browser-warning'] = 'true';
  resetBaseUrlAndToken() // only use temporary url for a single request
  return await fetch(url, options);
}

const setBaseUrlAndToken = (newUrl: string, newToken: string) => {
  TEMPORARY_API_URL = newUrl;
  TEMPORARY_API_TOKEN = newToken;
};

const resetBaseUrlAndToken = () => {
  if (TEMPORARY_API_URL !== API_URL) {
    TEMPORARY_API_URL = API_URL;
  }
  TEMPORARY_API_TOKEN = null;
};


const createNewInterview = async (interviewId: string, useCase: string, otherParticipant?: string): Promise<string> => {
  let url = `/interview?use_case_id=${encodeURIComponent(useCase)}&interview_id=${encodeURIComponent(interviewId)}`
  if (otherParticipant) {
    url += `&other_participant=${encodeURIComponent(otherParticipant)}`
  }
  const response = await authedFetch(url, {
    method: 'POST',
  });
  if (!response.ok) {
    throw new Error('Failed to fetch interview data');
  }
  const data = await response.json();
  return data.id
};

const saveAndTextToSpeech = async (interviewId: string, sectionNumber: number, questionNumber: number, summary: boolean, audioBlob: Blob) => {
  const response = await authedFetch(`/save_and_transcribe?interview_id=${interviewId}&section_number=${sectionNumber}&question_number=${questionNumber}&summary=${summary}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    body: await audioBlob.arrayBuffer(),
  });

  if (!response.ok) {
    throw new Error('Failed to save and transcribe audio');
  }

  return response.json();
};

const saveAndSummarize = async (sectionNumber: number, interviewContent: any, save: boolean = true) => {
  const response = await authedFetch(`/save_and_summarize?section_number=${sectionNumber}&save=${save}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(interviewContent),
  });

  if (!response.ok) {
    throw new Error('Failed to save and summarize');
  }

  return response.json();
};

const generateCommunication = async (interviewContent: any, communicationRequestId?: string) => {
  const response = await authedFetch(`/communication${communicationRequestId ? '?communication_request_id=' + communicationRequestId : ''}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(interviewContent),
  });

  if (!response.ok) {
    throw new Error('Failed to save and summarize');
  }

  return response.json();
};

const getUseCases = async (): Promise<Array<UseCase>> => {
  const response = await authedFetch(`/use_case`);

  if (!response.ok) {
    throw new Error(`Failed Use Cases get`);
  }

  return response.json();
};


const getCommunication = async (comm_or_interview_id: string, by_interview_id: boolean) => {
  const response = await authedFetch(`/communication/${comm_or_interview_id}?by_interview_id=${by_interview_id}`);

  if (!response.ok) {
    throw new Error(`Failed Communication get ${comm_or_interview_id}`);
  }

  return response.json();
};

const fetchUserData = async (): Promise<any> => {
  const response = await authedFetch(`/user`);
  if (!response.ok) {
    throw new Error('Failed to fetch user data');
  }
  return await response.json();
};

const updateUser = async (userUpdate: UserUpdate): Promise<any> => {
  const response = await authedFetch(`/user`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userUpdate)
  });
  if (!response.ok) {
    throw new Error('Failed to update user data');
  }
  return await response.json();
};

export interface AllCommunication {
  communications: Array<Communication>
  sharedCommunications: Array<Communication>
}

export interface Outline {
  text: string;
  subItems?: Array<Outline>;
}

export interface Communication {
  id: string;
  userId: string;
  title: string;
  text: string;
  audioUrl: string;
  audioText: string;
  created: string;
  oneOnOne: boolean;
  viewed: string; // date, null if not viewed
  outline?: Outline;
  outputs?: {
    email?: string;
    memoToFile?: string;
  }
}

const fetchCommunications = async (): Promise<AllCommunication> => {
  const response = await authedFetch(`/communication`);
  if (!response.ok) {
    throw new Error('Failed to fetch user data');
  }
  return await response.json();
};

const updateCommunication = async (communicationId: string, text: string): Promise<void> => {
  const response = await authedFetch(`/communication/${communicationId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ text }),
  });
  if (!response.ok) {
    throw new Error('Failed to update communication ' + communicationId);
  }
  return await response.json();
};

const shareCommunication = async (communicationId: string, email: string): Promise<void> => {
  const response = await authedFetch(`/communication/${communicationId}/share`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });
  if (!response.ok) {
    throw new Error('Failed to share communication ' + communicationId);
  }
  return await response.json();
};

const regenerateAudio = async (communicationId: string, text: string): Promise<string> => {
  console.log('regenerateAudio', communicationId, text);
  const response = await authedFetch(`/communication/${communicationId}/regenerate_audio`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ text }),
  });
  if (!response.ok) {
    throw new Error('Failed to regenerate audio');
  }
  const data = await response.json();
  return data.audioUrl;
}

const getGoogleAuthorizationUrl = async (): Promise<any> => {
  const response = await unauthedFetch(`/auth/google/authorize`);
  if (!response.ok) {
    throw new Error('Failed to fetch user data');
  }
  const data = await response.json();
  return data.authorization_url;
};

const getToken = async (queryString: string) => {
  const response = await unauthedFetch(`/auth/google/callback${queryString}`);
  if (!response.ok) {
    throw new Error('Failed to authenticate');
  }
  const data = await response.json();
  return data.access_token;
}

const getUseCase = async (id: string): Promise<UseCase> => {
  const response = await authedFetch(`/use_case/${id}`);
  if (!response.ok) {
    throw new Error('Failed to fetch use case');
  }
  return await response.json();
}

const getCommunicationRequests = async (): Promise<CommunicationRequestsAll> => {
  const response = await authedFetch(`/communication_request`);
  if (!response.ok) {
    throw new Error('Failed to get communication requests');
  }
  return await response.json();
}

const getCommunicationRequestCounts = async (): Promise<CommunicationRequestCounts> => {
  const response = await authedFetch(`/communication_request/counts`);
  if (!response.ok) {
    throw new Error('Failed to get communication request counts');
  }
  return await response.json();
}

const getCommunicationShareCounts = async (): Promise<CommunicationShareCounts> => {
  const response = await authedFetch(`/communication_share/counts`);
  if (!response.ok) {
    throw new Error('Failed to get communication request counts');
  }
  return await response.json();
}

const createCommunicationRequest = async (add: CommunicationRequestAdd): Promise<void> => {
  const response = await authedFetch(`/communication_request`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(add),
  });
  if (!response.ok) {
    throw new Error('Failed to create communication request');
  }
  return await response.json();
}

const remindCommunicationRequest = async (communicationRequestId: string): Promise<void> => {
  const response = await authedFetch(`/communication_request/${communicationRequestId}/remind`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to remind communication request');
  }
  return await response.json();
}

const markCommunicationRequestAsViewed = async (requestId: string): Promise<void> => {
  const response = await authedFetch(`/communication_request/${requestId}/viewed`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to create mark request as viewed');
  }
  return await response.json();
}

const markCommunicationShareAsViewed = async (communicationId: string): Promise<void> => {
  const response = await authedFetch(`/communication/${communicationId}/share/viewed`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to create mark request as viewed');
  }
  return await response.json();
}

const getResponsiveQuestions = async (interviewId: string, sectionNumber: number, interview: any) => {
  const response = await authedFetch(`/interview/${interviewId}/responsive_questions?section_number=${sectionNumber}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(interview),
  });
  if (!response.ok) {
    throw new Error('Failed to generate responsive questions');
  }
  return await response.json();
}

const generateAudio = async (interviewId: string, sectionNumber: number, questionNumber: number,
  question: string): Promise<string> => {
  const queryParams: Record<string, string> = {
    interview_id: interviewId,
    section_number: sectionNumber.toString(),
    question_number: questionNumber.toString(),
    question: question,
  }
  const response = await authedFetch(`/generate_audio?${new URLSearchParams(queryParams).toString()}`);
  if (!response.ok) {
    throw new Error('Failed to generate generateAudio');
  }
  const data = await response.json();
  return data.audioUrl;
}

const generatePPTX = async (communicationId: string): Promise<Response> => {
  const response = await authedFetch(`/communication/${communicationId}/generate_pptx`, {
    method: 'GET',
  });
  if (!response.ok) {
    throw new Error('Failed to generate PPTX');
  }
  return response;
};

const addAnswer = async (interviewId: string, sectionNumber: number, questionNumber: number, isSummaryAnswer: boolean, answer: string, audioUrl: string, audioLength: number): Promise<void> => {
  const response = await authedFetch(`/interview/${interviewId}/add_answer?section_number=${sectionNumber}&question_number=${questionNumber}&is_summary_answer=${isSummaryAnswer}&answer=${encodeURIComponent(answer)}&audio_url=${encodeURIComponent(audioUrl)}&audio_length=${audioLength}`, {
    method: 'POST',
  });
  if (!response.ok) {
    throw new Error('Failed to add answer');
  }
  return await response.json();
}

const api = {
  markCommunicationRequestAsViewed,
  getCommunicationRequests,
  getCommunicationRequestCounts,
  createCommunicationRequest,
  remindCommunicationRequest,
  getUseCase,
  fetchUserData,
  updateUser,
  fetchCommunications,
  regenerateAudio,
  updateCommunication,
  getGoogleAuthorizationUrl,
  createNewInterview,
  saveAndTextToSpeech,
  saveAndSummarize,
  generateCommunication,
  getCommunication,
  shareCommunication,
  getToken,
  getUseCases,
  getCommunicationShareCounts,
  markCommunicationShareAsViewed,
  setBaseUrlAndToken,
  getResponsiveQuestions,
  generateAudio,
  generatePPTX,
  addAnswer
}

export default api