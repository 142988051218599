import React, { useState } from 'react';
import { useUserContext } from '../hooks/useUserContext';

interface EmailPopupProps {
  onClose: () => void;
  onEnterEmail: (email: string) => void;
  title: string;
  buttonText: string;
}

const EmailPopup: React.FC<EmailPopupProps> = ({ onClose, onEnterEmail, title, buttonText }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const { user } = useUserContext();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (email.toLowerCase() === user.email.toLowerCase()) {
      setError("You can't share with yourself.");
    } else {
      setError('');
      onEnterEmail(email);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-sm mx-4">
        <h2 className="text-xl font-bold mb-4">{title}</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email address"
            className="w-full px-3 py-2 border border-gray-300 rounded-md mb-2"
            required
          />
          {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-600 bg-gray-200 rounded-md"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-white bg-blue-500 rounded-md"
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailPopup;