import React from 'react';
import { BiPause, BiPlay } from 'react-icons/bi';
import Spinner from './Spinner';

interface PlayPauseLoadingButtonProps {
  isLoading: boolean;
  isDisabled: boolean;
  isPlaying: boolean;
  onClick: () => void;
}
const PlayPauseLoadingButton: React.FC<PlayPauseLoadingButtonProps> = ({ isLoading, isDisabled, isPlaying, onClick }) => {
  const playButtonColors = isDisabled ? 'bg-gray-400' :
    'bg-red-500 hover:bg-red-600 active:bg-red-700';
  const PlayPause = isPlaying && !isDisabled ? BiPause : BiPlay
  if (isLoading) {
    return <Spinner />
  }
  return (
    <button
      onClick={onClick}
      className={`flex items-center w-16 h-16 justify-center rounded-full ${playButtonColors}`}
    >
      <PlayPause className="text-white text-5xl" />
    </button>
  );
};

export default PlayPauseLoadingButton;
