import React from 'react';
import Spinner from './Spinner';
import PulsingRecordButton from './PulsingRecordButton';
import Header from './Header';
import AdjustAudio from './AdjustAudio';
import PlayPauseLoadingButton from './PlayPauseLoadingButton';
import { BiArrowBack } from 'react-icons/bi';

export enum InterviewUIState {
  SPEAKING,
  LISTENING,
  PROCESSING,
  PAUSED
}

interface InterviewUIProps {
  title: string;
  state: InterviewUIState;
  onSkipSpeaking: () => void;
  onStopRecording: () => void;
  processingText?: string;
  onClose: () => void;
  onBack: () => void;
  isAirPlayAvailable: boolean;
  showAirPlayPicker: () => void;
  togglePauseReading: () => void;
}

const InterviewUI: React.FC<InterviewUIProps> = ({ onClose, onBack, title, state, onSkipSpeaking, onStopRecording,
  processingText, isAirPlayAvailable, showAirPlayPicker, togglePauseReading }) => {

  const logoClassName = () => {
    switch (state) {
      case InterviewUIState.SPEAKING:
        return 'transform scale-100 opacity-100';
      case InterviewUIState.PAUSED:
        return 'transform scale-100 opacity-100';
      case InterviewUIState.LISTENING:
        return 'transform scale-125 opacity-50';
      case InterviewUIState.PROCESSING:
        return 'transform scale-100 opacity-50';
    }
  }

  return (
    <div className="h-screen-dynamic flex flex-col items-center justify-between bg-white">
      <div className="w-full">
        <Header title={title} onClose={onClose} />
        <AdjustAudio isAirPlayAvailable={isAirPlayAvailable} showAirPlayPicker={showAirPlayPicker} />
      </div>
      <div className={`flex items-center justify-center transition-transform duration-500 ${logoClassName()}`}>
        <img src="img/main-logo.png" alt="Logo" className="w-48 cursor-pointer" />
      </div>
      <div className="w-full bg-skin text-center rounded-t-3xl p-6 h-64 flex items-center justify-center relative">
        <div className={`transition-opacity duration-500 ${state === InterviewUIState.LISTENING ? 'opacity-100 z-10' : 'opacity-0 z-0'} absolute inset-0 flex flex-col items-center justify-center`}>
          <p className="text-gray-800 text-2xl font-bold mb-6">I am Listening…</p>
          <div className="relative w-full">
            <button
              onClick={onBack}
              className="absolute left-6 top-1/2 transform -translate-y-1/2 flex items-center justify-center w-12 h-12 rounded-full bg-gray-200 hover:bg-gray-300 active:bg-gray-400"
            >
              <BiArrowBack className="text-gray-600 text-2xl" />
            </button>
            <div className="flex justify-center">
              <PulsingRecordButton onStopRecording={onStopRecording} />
            </div>
          </div>
          <p className="text-gray-600 mt-6">Tap to Stop Recording</p>
        </div>
        <div className={`transition-opacity duration-500 ${state === InterviewUIState.SPEAKING || state === InterviewUIState.PAUSED ? 'opacity-100 z-10' : 'opacity-0 z-0'} absolute inset-0 flex flex-col items-center justify-center`}>
          <p className="text-gray-800 text-2xl font-bold mb-6">beSpokecast.ai is {state === InterviewUIState.SPEAKING ? 'speaking' : 'paused'}</p>
          <div className="flex items-center space-x-4">
            <PlayPauseLoadingButton
              isLoading={false}
              isDisabled={false}
              isPlaying={state === InterviewUIState.SPEAKING}
              onClick={togglePauseReading}
            />
          </div>
        </div>
        <div className={`transition-opacity duration-500 ${state === InterviewUIState.PROCESSING ? 'opacity-100 z-10' : 'opacity-0 z-0'} absolute inset-0 flex flex-col items-center justify-center`}>
          <p className="text-gray-800 text-2xl font-bold mb-4">{processingText || 'Processing'}</p>
          <Spinner />
        </div>
      </div>
    </div>
  );
};

export default InterviewUI;
