import React from 'react';
import BasicButton from './BasicButton';
import { FaChevronLeft } from 'react-icons/fa';
import { useUserContext } from '../hooks/useUserContext';
import { Link } from 'react-router-dom';

interface HeaderProps {
  title: string;
  onClose?: () => void;
  onBack?: () => void;
  onHeaderButton?: () => void;
  headerButtonText?: string;
  adminLinkPath?: string;
}

const Header: React.FC<HeaderProps> = ({ title, onClose, onHeaderButton, onBack, headerButtonText, adminLinkPath }) => {
  const { user } = useUserContext()
  return (
    <div className="flex justify-between items-center mt-6 mb-2 mx-4 mx-auto max-w-3xl w-full px-4">
      <div className="flex items-center">
        {onBack && <FaChevronLeft onClick={onBack} className="text-gray-500 mr-2" />}
        <h1 className="text-2xl font-bold">{title}</h1>
        {user.isSuperuser && adminLinkPath && (
          <Link to={adminLinkPath} className="ml-4 text-sm text-blue-600 hover:text-blue-800">
            Admin
          </Link>
        )}
      </div>
      <div className="flex items-center">
        {onHeaderButton && headerButtonText && <BasicButton onClick={onHeaderButton} text={headerButtonText} />}
        {onClose && (
          <button
            className="text-gray-500 hover:text-gray-700 text-2xl font-bold leading-none ml-4 focus:outline-none"
            onClick={onClose}
          >
            &times;
          </button>
        )}
      </div>
    </div>
  );
}

export default Header;