import React from 'react';
import GradientBespoke from './GradientBespoke';

const ErrorFallback: React.FC<{}> = () => {
  return (
    <div className="h-screen-dynamic flex flex-col items-center justify-center bg-white">
      <div className="flex flex-col items-center">
        <img src="img/main-logo.png" alt="Logo" className="w-48 mb-8" />
        <GradientBespoke />
        <div>Sorry, we encountered an unexpected error</div>
        <button
          onClick={() => window.location.href = '/'}
          className="flex items-center px-6 py-3 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-100 mt-8">
          <span>Return Home</span>
        </button>
      </div>
      {/* Terms and Privacy links */}
      <div className="absolute bottom-0 left-0 w-full p-2 text-center text-xs">
        <a href="/privacy.html" className="text-gray-500 hover:text-gray-700">Privacy</a>
        <span className="mx-1 text-gray-500">|</span>
        <a href="/terms.html" className="text-gray-500 hover:text-gray-700">Terms</a>
      </div>
    </div>)
}

export default ErrorFallback;
