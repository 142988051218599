import React from 'react';

interface PulsingRecordButtonProps {
  onStopRecording: () => void;
}

const PulsingRecordButton: React.FC<PulsingRecordButtonProps> = ({ onStopRecording }) => {
  return (
    <div
      className="flex items-center justify-center"
      onClick={(e) => {
        e.stopPropagation();
        onStopRecording();
      }}
    >
      <div className="relative flex items-center justify-center cursor-pointer">
        <div className="absolute w-24 h-24 bg-red-200 rounded-full animate-pulse-slow"></div>
        <div className="relative w-16 h-16 bg-red-500 rounded-full flex items-center justify-center">
          <div className="w-6 h-6 border-2 border-white-500 rounded-md bg-transparent"></div>
        </div>
      </div>
    </div>
  );
}

export default PulsingRecordButton;